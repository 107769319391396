.h1{
    &-raw{
        font-size: 50px;
        font-size: 5.0rem;
        // font-weight: 600;
        //line-height: 1.2em;
        line-height:60px;
        font-family : $fancy-font;
        text-transform: uppercase;
        color: $core-color-font;
        font-weight : 400;
        
        @include RWD(mobile){
            font-size: 21px;
            font-size: 2.1rem;
            letter-spacing: 0.15em;
        }
    }
    @extend .h1-raw;
    // Ajouter ici les styles "fancy", paddings, after before etc...


}

.h2{
    &-raw{
        font-family: $fancy-font;
        color: $core-color-font;
        font-size: 4rem; 
        font-weight: 400;
        line-height: 40px;
        // margin-bottom : 35px;

        @include RWD(mobile){
            font-size: 2.2rem;
        }
    }
    @extend .h2-raw;
    // Ajouter ici les styles "fancy", paddings, after before etc...
    border-bottom : 2px solid $core-color;

}

.h3{
    &-raw{
        font-family: $fancy-font;    
        font-weight: 400;
        font-size: 2.6rem;
        color: $core-color;
        line-height: 40px;
        
        @include RWD(mobile){
            font-size: 2rem;
        }
    }
    @extend .h3-raw;
    // Ajouter ici les styles "fancy", paddings, after before etc...

}

.h4{
    &-raw{
        font-family: $main-font;    
        font-weight: 700;
        font-size: 2.2rem;
        color: $core-color-font;
        line-height: 32px;

        @include RWD(mobile){
            font-size: 1.4rem;
        }
    }
    @extend .h4-raw;
    // Ajouter ici les styles "fancy", paddings, after before etc...

}

.h5{
    &-raw{            
        font-size: 1.4rem;
        line-height: 1.2em;
        text-transform: uppercase;
    }
    @extend .h5-raw;
    // Ajouter ici les styles "fancy", paddings, after before etc...

}

.h6{
    &-raw{            
        font-size: 1.4rem;
        line-height: 1.2em;
        font-weight: 700;
    }
    @extend .h6-raw;
    // Ajouter ici les styles "fancy", paddings, after before etc...

}