.ariane{
    margin-top:60px;

    @include RWD(tablet){
        margin-left: 50px;
    }
    
    .back{
        display: none;
        font-size: 16px;
        font-size: 2.2rem;
        color: $text-color;
        text-decoration: none;
        font-family:$fancy-font;

        &:hover, &:focus{
            text-decoration: underline;
        }

        &:before{
            content: '<   '; 
        }
        @include RWD(mobile){
            display: block;
        }
    }
    .page-depth{
        display: flex;
        flex-wrap: wrap;
    
        li, li a{
            //font-size: 16px;
            font-size: 2.2rem;
            color: $text-color;
            text-decoration: none;
        }
        li a{
            &:hover, &:focus{
                text-decoration: none;
            }
        }
        li{
            display:flex;
            &:after{
                content: '';
                display: block;
                height: 15px;
                width: 20px;
                background-size: 15px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url( svg-bg-uri($svg-arrow1, #7e7e7e) );
                margin-left: 20px;
                margin-right: 30px;
                align-self:center;
            }
            &:last-child{
                &:after{
                    display: none;
                }
            }
            .page.active{
                //opacity: 0.7;
                color:$core-color;
                font-family:$fancy-font;
                font-size:2.2rem;
            }
        }
    
        @include RWD(mobile){
            display: none;
        }
    }
}