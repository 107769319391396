
%degug-show {
	$transition-delay: .1s;
	height: 50%;
	padding-left: 2rem;
	transform: none;
	transition-delay: $transition-delay;
	animation: none;
	&:before {
		height: 0;
		width: 0;
		opacity: 0;
		transition-delay: $transition-delay;
	}
	a,
	.debug-master__separator {
		color: #fff;
	}
}
.debug-master {
	$svg-gear: '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" viewBox="0 0 17.6 16.9"><defs><rect x="0.2" width="18" height="16.9"/></defs><path class="st0" d="M15.1 6.4l-0.4-1c0 0 1-2.3 0.9-2.4l-1.3-1.3c-0.1-0.1-2.4 1-2.4 1l-1.1-0.4c0 0-0.9-2.3-1.1-2.3H7.8c-0.1 0-1 2.3-1 2.3L5.8 2.6c0 0-2.3-1-2.4-0.9L2 3.1C1.9 3.1 3 5.4 3 5.4l-0.4 1c0 0-2.4 0.9-2.4 1v1.9c0 0.1 2.4 1 2.4 1l0.4 1c0 0-1 2.3-0.9 2.4L3.4 15c0.1 0.1 2.4-1 2.4-1l1.1 0.4c0 0 0.9 2.3 1.1 2.3h1.9c0.1 0 1-2.3 1-2.3l1.1-0.4c0 0 2.3 1 2.4 0.9l1.3-1.3c0.1-0.1-1-2.3-1-2.3l0.4-1c0 0 2.4-0.9 2.4-1V7.4C17.5 7.3 15.1 6.4 15.1 6.4L15.1 6.4zM11.6 8.3c0 1.5-1.2 2.7-2.8 2.7 -1.5 0-2.8-1.2-2.8-2.7 0-1.5 1.2-2.7 2.8-2.7C10.4 5.6 11.6 6.9 11.6 8.3L11.6 8.3zM11.6 8.3"/></svg>';
	position: fixed;
	right: 0;
	bottom: 2rem;
	z-index: 9999;
	height: 10%;
	width: 20%;
	min-width: 30rem;
	overflow: auto;
	display: none;
	padding: 2rem;
	padding-left: 3rem;
	background: #222;
	transform: translateX(90%);
	transition: .2s;
	border-radius: 1rem;
	max-height: 50rem;
	max-width: 30rem;
	animation: debugIntro 1s;
	@mixin box-shadow($opacity) {
		box-shadow: 0 0 1rem rgba($color: #222, $alpha: $opacity);
	}
	@include box-shadow(.3);
	@keyframes debugIntro {
		50% {
			padding-left: 5rem;
			transform: translateX(85%);
			border: 2px solid #ce3838;
			@include box-shadow(.8);
		}
	}
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: .5rem;
		height: 2rem;
		width: 2rem;
		display: inline-block;
		background-image: url(svg-bg-uri($svg-gear,#d6d6d6));
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		transform: translateY(-50%);
		flex-shrink: 0;
	}
	&:hover {
		@extend %degug-show;
	}
	&.debug-pinned {
		right: 1rem;
		opacity: .3;
		text-shadow: -.5rem .5rem 1rem rgba(0, 0, 0, .3);
		border: 1px solid #222;
		@extend %degug-show;
		@include box-shadow(.5);
		&:hover {
			opacity: .9;
		}
	}
	a {
		font-size: 1.2rem;
		color: transparent;
		text-decoration: none;
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
	&__pin {
		content: '';
		position: absolute;
		top: 2rem;
		right: 2rem;
		height: 2rem;
		width: 2rem;
		display: inline-block;
		background-image: url(svg-bg-uri($svg-close,#fff));
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		cursor: pointer;
		transform: rotate(-45deg);
		transition: .2s;
		flex-shrink: 0;
		@at-root {
			.debug-pinned & {
				transform: none;
			}
		}
	}
	&__content {
		font-size: 2rem;
		line-height: 1.5em;
	}
	&__separator {
		color: transparent;
	}
}