noscript{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: #000000;
    color: #ffffff;
    padding: 20px;
    font-size: 1.4rem;
    font-family: $main-font;
    display: block;
    animation: js_appear 2s 10s;
    opacity: 1;
}
