// IE 11 only
_:-ms-fullscreen,
:root {

	.page-navigation404 #main-content .link-group .link-group-item svg,
	.not-front .rte .link-group .link-group-item svg,
	.not-front #comments .link-group .link-group-item svg,
	.page-sitemap #site-map .link-group .link-group-item svg {
		height: 30px;
	}

	.not-front #ui-datepicker-div select,
	.class_group_intern #ui-datepicker-div select {
		background: none;
		padding: 0;
	}

	.class_group_intern .view-actualites .view-filters .webform-layout-box,
	.class_group_intern .view-agenda .view-filters .webform-layout-box,
	.class_group_intern .view-maps .view-filters .webform-layout-box,
	.class_group_intern.page-search-site #search-form .webform-layout-box,
	.class_group_intern .webform-client-form .webform-layout-box,
	.class_group_intern #comments form .webform-layout-box,
	.not-front .view-actualites .view-filters .webform-layout-box,
	.not-front .view-agenda .view-filters .webform-layout-box,
	.not-front .view-maps .view-filters .webform-layout-box,
	.not-front.page-search-site #search-form .webform-layout-box,
	.not-front .webform-client-form .webform-layout-box,
	.not-front #comments form .webform-layout-box {
		& > .form-group {
			flex: 1;
		}
	}

	.agenda__filtres__item:hover::before {

		content: '';
		position: absolute;
		bottom: 4px;
		width: 100%;
		height: 2px;
		background: $core-color !important;
	}

	.agenda__cards__content__event__nom::before {

		content: '';
		height: 2px;
		min-width: 40px;
		width: 40px;
		display: block;
		background: $core-color !important;
		-webkit-transform: translateY(5px);
		transform: translateY(5px);
		-ms-flex-item-align: center;
		align-self: center;
		margin-left: 10px;
		margin-right: 10px;

	}

	.agenda__cards__content__event a {
		@include RWD(desktop) {
			width: 65%;
		}
	}

	.user-alert-body {
		color: $core-color-font;
		font-size: 1.6rem;
		line-height: 1.2em;
		padding-left: 4px;
		flex: 1;
		font-family: $fancy-font;

		@include RWD(tablet) {
			padding-right: 40px;
			width: auto;
			margin-left: 30px;
		}

		@include RWD(mobile) {
			font-size: 1.3rem;
			width: 100%;
			//margin-top: 5px;
			padding: 0;
			margin-left: 20px;
		}

		& div {
			display: flex;
			align-items: center;
			flex-direction: row;
			margin-left: 30px;

			@include RWD(tablet) {
				margin-left: 0px;
			}

			@include RWD(mobile) {
				align-items: start;
				flex-direction: column;
			}

			& p {
				margin-left: 3px;
				font-family: $main-font;
				font-size: 1.8rem;
				//display:flex;
				display: inline;
				align-items: center;
				width: 100%;
				transform: translateY(-3px);

				@include RWD(tablet) {
					width: 100%;
					top: 10px;
				}

				@include RWD(mobile) {
					flex-direction: column;
					align-items: start;
					font-size: 1.6rem;
					margin-right: 45px;
					width: 60%;
				}
			}
		}
	}

	.user-alert-banner {
		.close {
			position: absolute;
			top: 16px;

			@include RWD(tablet) {
				top: 10px;
			}

			@include RWD(tablet) {
				top: 16px;
			}

			@include RWD(mobile) {
				top: -5px;
				transform: none;
			}
		}

		.user-alert-right {
			width: 100%;
		}
	}

	.user-alert-title {
		@include RWD(tablet) {
			transform: translateY(-4px);
		}
	}

	.user-alert-left {
		@include RWD(desktop) {
			width: 200%;
		}
	}

	.cookies-txt {
		@include RWD(tablet) {
			width: 80%;
		}
		@include RWD(mobile){
			width:50%;
		}
	}

	.cookies__icon{
		@include RWD(mobile){
			margin-right:0px;
			transform: translateX(5px);
		}
	}

	.a-btn-main .btn-text{
		height:100%;
		transform:translateY(5px);
	}
}