.a-download{
    text-align: right;
    &__informations{
        display: flex;
        flex-direction: column;
    }
    &__weight{
        text-align: right;
        margin-bottom: 10px;
        @include RWD(mobile){
            text-align: left;
        }
    }
    &__btn{
        .btn-arrow{
            width: 20px;
            height: 20px;
            background-image: url( svg-bg-uri($svg-download, $core-color) );
        }
        &:hover, &:focus{
            .btn-arrow{
                background-image: url( svg-bg-uri($svg-download, $second-color) );
            }
        }
    }
}