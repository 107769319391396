//  Grille de notation
// Dépendance au table rte
.form__field--grid{
    overflow: auto;
}
    
.a-form-grid{
    .form__label{
        display: none;
    }
    .form__field{
        margin: 0;
    }
    input{
        margin: 0; 
        height: auto;
        width: auto;
    }
    width: 100%;
    border-spacing: 0;
    @include RWD(tablet){
        width: auto;
        max-width: 100%;
    }
}