.a-infos-pratiques{
    // display: flex;
    // Dates
    &--date{
        width: #{$break-large / 2}px;
        max-width: 100%;
    }
    
    // Titre de section
    &__title {
        background-color: $core-color;
        color: $core-color-font;
        font-size: 20px;
        font-size: 2.0rem;
        font-weight: 500;
        width: 100%;
        padding: 30px 20px;
        margin-bottom: 0;
        @include RWD(mobile) {
            padding: 20px 15px;
        }
    }

    // Box contenu
    &__content {
        padding: 30px 40px 10px;
        background-color: #f7f7f7;
        width: 100%;
        @include RWD(mobile) {
            padding: 20px 20px 0px;
        }
    }

    .dates-list{
        margin-bottom: 40px;
        clear: both;
        &__item{
            margin-bottom: $margin-p;
            position: relative;
            display: flex;
            align-items: center;
            @include RWD(mobile){
                flex-wrap: wrap;
            }
            &:before {
                content: '';
                height: 7px;
                width: 7px;
                background-color: $second-color;
                border-radius: 500px;
                margin-right: 6px;
                display: block;
                @include RWD(mobile) {
                    display: none;
                }
            }
        }
        &__date{
            flex: 1;
        }
        .add-cal-module {
            margin-left: 10px;
            position: relative;
            flex-shrink: 0;
            @include RWD(mobile){
                width: 100%;
                margin-left: 0;
                margin-top: 10px;
            }
            &:hover{
                .addtocal_menu{
                    display: block;
                }
            }
            .addtocal_menu {
                display: none;
                position: absolute;
                top: 100%;
                right: 0;
                background-color: #FFFFFF;
                width: 100%;
                padding: 5px 10px;
                z-index: 10;
                border: solid 1px $core-color;
                border-top: none;
                li {
                    margin-bottom: 5px;
                }
                a {
                    color: $core-color;
                    text-decoration: none;
                    display: flex;
                    &:hover,
                    &:focus {
                        color: $text-color;
                    }
                    &:before {
                        content: '>';
                        margin-right: 5px;
                    }
                }
            }
            .addtocal {
                background-color: $core-color;
                color: $core-color-font;
                font-weight: 600;
                padding: 10px;
                transition: all, 0.5s;
                &:hover {
                    background-color: $second-color;
                }
                &:focus {
                    &+.addtocal_menu {
                        display: block;
                    }
                }
            }
        }
    }
    
    .infos-list{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
        clear: both;
        &__item{
            width: 50%;
            min-width: 50%;
            margin-bottom: 20px;
            &:nth-child(2n){
                padding-left: 10px;
            }
            &:nth-child(2n+1){
                padding-right: 10px;
            }
            @include RWD(mobile) {
                width: 100%;
                min-width: 100%;
                padding: 0 !important;
            }
        }    
        &__label { 
            margin-bottom: 20px;
        }
        &__item--map{
            .a-gmap{
                width: 300px;
                height: 300px;
                max-width: 100%;
            }
            .map{
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}