

/**
    ---------- SKIN Dragibus
*/

.grid-morph--dragibus{
    .gridy__item{
        margin-bottom: 30px;
    }
}
.a-teaser-morph--dragibus{
    display: block;
    margin: 0 15px;
    text-decoration: none; 
    transition: all, 0.5s; 
    @include RWD(mobile){ 
        width: 100%;
        margin: 0;
        margin-bottom: 30px;
    }
    &__background{
        background-size: cover;
        height: auto;
        padding-bottom: 100%;
        border-radius: 5px;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 18px; 
        position: relative;

        &:before{
            content: '';
            display: inline-block;
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 0;
            transition: all, 0.5s;
            border-radius: 5px;
        }
    }
    &__title{
        color: #757575;
        transition: transform 0.2s 0.2s, padding 0s 0.2s, color 0.5s;
        animation: fade2 0.5s;
    }
}
a.a-teaser-morph--dragibus{

    &:hover, &:focus{
        transform: translateY(20px);
        text-decoration: none;
        .a-teaser-morph--dragibus__background{
            &:before{
                opacity: 1;
            }
        }
        @keyframes fade{
            0%{opacity: 1}
            50%{opacity: 0}
            100%{opacity: 1}
        }
        @keyframes fade2{
            0%{opacity: 1}
            50%{opacity: 0}
            100%{opacity: 1}
        }
        .a-teaser-morph--dragibus__title{
            transform: translateY(-80px);
            padding: 0 20px;
            color: #FFFFFF;
            animation: fade 0.5s;
            animation-fill-mode: forwards;
            transition: transform 0.2s 0.2s, padding 0s 0.2s, color 0.5s;
        }
    }
}