.#{$sfull_name} {
	&-thumbnail {
		margin-top: 40px;

		@include RWD(mobile) {
			margin-top: 20px;
		}

		/* ITEM
        ============================= */

		&__slider-item {
			position: relative;
			height: auto;
			padding-bottom: 57%;
		}

		&__slide {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			cursor: pointer;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				content: '';
				z-index: 2;
				height: 100%;
				width: 100%;
				background-color: rgba(#000000, 0.5);
				background-image: url(svg-bg-uri($svg-morecircled, #FFFFFF));
				background-position: center;
				background-size: 40px 40px;
				background-repeat: no-repeat;
				opacity: 0;
				transition: all, 0.5s;
			}

			&:hover,
			&:focus {
				&:before {
					opacity: 1;
				}
			}

			&--video {
				&:before {
					display: block;
				}

				.owl-video-tn {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
				}

				.owl-video-play-icon {
					display: none;
				}

				.owl-video-wrapper {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;

					&:before {
						display: none;
					}
				}
			}
		}


		/* ARROWS
        ============================= */

		&__arrow {
			@include RWD(mobile) {
				display: none;
			}


			&-icon {}

			&-pagination {
				display: none;
			}

			&--prev {}

			&--next {}

			&--total {}
		}

		/* Pager
        ============================= */

		&__pager {
			&--current {}

			&--prev {}

			&--next {}
		}


		/* DOTS
        ============================= */

		&__dot {


			&.active {}
		}

		/* Playpause
        ============================= */

		&__playpause {
			display: none;

			&.play {}

			&.pause {}
		}

		/* Slider Text
        ============================= */

		&__text {
			display: none !important;
		}
	}
}