.region-nav-tools{
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;     
    margin-bottom: 20px;
    margin-top: 20px;

    .filler{
        flex: 1;
    }

    .block-container-breadcrumb{
        margin-right: auto;
    }
}