.a-checkbox{
    position: absolute;
    opacity: 0;
    & + label{
        display: flex;
        align-items: center;
        &:before{
            content: '';
            display: block;
            height: 15px;
            width: 15px;
            border: solid 2px $core-color;
            background-color: #ffffff;
            border-radius: 5px;
            margin-right: 13px;
            flex-shrink: 0;
        }
    }
    &:checked + label{
        &:before{
            background-image: url( svg-bg-uri($svg-check, $core-color) );
            background-size: cover;
            background-position: center;
        }
    }
}