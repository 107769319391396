.connexion {
	&-overlay {
		width: 100vw;
		height: 100vh;
		background-color: grey;
		opacity: 0.8;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&-module {
		height: 300px;
		width: 1000px;
		background: $page-bg-color;
		padding-top: 60px;
		position: relative;

		@include RWD(tablet) {
			width: calc(100% - 80px);
			//transform:translateX(-40px);
		}

		@include RWD(mobile) {
			width: calc(100% - 40px);
			height: auto;
			padding-top: 20px;
			padding-bottom: 20px;
		}

		&-close {
			position: absolute;
			cursor: pointer;
			height: 25px;
			width: 25px;
			background-size: 25px;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(svg-bg-uri($svg-morecircled, $core-color-font));
			transform: rotate(45deg);
			right: 30px;
			top: 30px;

			@include RWD(mobile) {
				top: 5px;
				right: 5px;
			}
		}
	}

	&-ariane {
		display: flex;
		flex-direction: row;
		height: auto;
		align-items: center;
		padding-left: 200px;

		@include RWD(tablet) {
			padding-left: 100px;
		}

		@include RWD(mobile) {
			padding-left: 20px;
		}

		&-item {
			font-size: 2.2rem;

			&-accueil {
				font-family: $main-font;
			}

			&-picto {
				background-image: url(svg-bg-uri($svg-arrow1, $text-color));
				height: 15px;
				width: 15px;
				background-position: center;
				background-repeat: no-repeat;
				margin-left: 25px;
				margin-right: 30px;

				@include RWD(mobile) {
					margin-left: 15px;
					margin-right: 20px;
				}
			}

			&-connexion {
				font-family: $fancy-font;
				color: $core-color;
			}
		}
	}

	&-form {
		padding-left: 340px;
		margin-top: 40px;

		@include RWD(tablet) {
			padding-left: 240px;
		}

		@include RWD(mobile) {
			padding-left: 20px;
			margin-top: 20px;
		}

		&-label {
			font-size: 3rem;
			font-family: $fancy-font;
			color: $core-color-font;
			margin-right:20px;
			width: auto;

			@include RWD(mobile) {
				font-size: 1.8rem;
				line-height: 24px;
				width:70px;
				margin-right:10px;
			}
		}

		&-item {
			display: flex;
			flex-direction: row;
			margin-right: 10px;
			@include RWD(mobile){
				align-items: end;
			}
		}

		&-input {
			border: 1px solid transparent;

			// &-login {
			// 	@include RWD(mobile) {
			// 		transform: translateY(-10px);
			// 	}
			// }
			// &-pass {
			// 	@include RWD(mobile) {
			// 		transform: translateY(-5px);
			// 	}
			// }


			&:active {
				border: 1px solid transparent;
			}
		}

		&-password {
			&-label {
				margin-top: 5px;

				@include RWD(mobile) {
					align-items: end;
				}
			}
		}
	}
}