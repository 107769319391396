

.a-audio-player {
	background: #ffffff;
	width: 720px;
	display: flex;
	margin: 0 auto;
	max-width: 100%;
	height: 150px;
	align-items: center;
	-webkit-box-shadow: 0px 0px 5px 10px rgba(33, 33, 33, 0.05);
	-moz-box-shadow: 0px 0px 5px 10px rgba(33, 33, 33, 0.05);
	box-shadow: 0px 0px 5px 10px rgba(33, 33, 33, 0.05);

	@include RWD(mobile) {
		display: block;
        padding: 20px;
        height:auto;
	}


	audio {
		display: none;
	}

	// Visuel
	.audio-visu {
		text-align: center;
		background-color: $core-color;
		height: 140px;
		width: 140px;
		margin-left: 5px;

		@include RWD(mobile) {
			margin-bottom: 20px;
		}

		img {
			height: 140px;
			width: 140px;
		}
	}

	// Partie droite infos
	.audio-infos {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		padding: 20px;

		@include RWD(mobile) {
			padding: 0px;
		}
	}

	.audio-title {
		margin-bottom: 10px;
		font-size: 22px;
		font-size: 2.2rem;
		font-weight: 700;
		color: $core-color-font;
		font-family: $main-font;
	}

	.audio-artist {
		margin-bottom: 5px;
		font-size: 16px;
		font-size: 1.6rem;
		font-weight: 400;
		color: $core-color-font;
		font-family: $main-font;

		@include RWD(mobile) {
			margin-bottom: 20px;
		}
	}

	// Lecteur audio
	.audio-player {
		width: 420px;
		display: flex;
        max-width: 100%;
        transform: translateY(-5px);

		// Bouton playpause
		.player-control {
			height: 30px;
			width: 30px;
			margin-right: 40px;
			cursor: pointer;
			transition: all, 0.5s;

			.flexbox {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.btn-play {
				display: block;
				height: 30px;
                width: 30px;
                background-size:30px;
                background-position:center;
                background-repeat:no-repeat;
                background-image: url(svg-bg-uri($svg-player, $core-color));
			}

			.btn-pause {
				display: none;
				justify-content: space-between;
                margin: 0 auto;
                height: 30px;
                width: 30px;
                background-size:30px;
                background-position:center;
                background-repeat:no-repeat;
                background-image: url(svg-bg-uri($svg-pause, $core-color));

				// &:before {
				// 	content: "";
				// 	height: 100%;
				// 	width: 4px;
				// 	display: block;
				// 	background-color: #42424b;
				// }

				// &:after {
				// 	content: "";
				// 	height: 100%;
				// 	width: 4px;
				// 	display: block;
				// 	background-color: #42424b;
				// }
			}

			.hidden {
				display: none;
			}

			&.playing {
				.btn-play {
					display: none;
				}

				.btn-pause {
					display: flex;
				}
			}

			&:hover,
			&:focus {
				opacity: 0.5;
			}
		}

		// Vue bar + temps
		.player-view {
			flex: 1;

			.player-timing {
				display: flex;
				justify-content: space-between;
                //margin-bottom: 5px;
                line-height: 25px;

				.player-time-current {
					font-size: 11px;
					font-size: 1.1rem;
					color: #1c1c1c;
				}

				.player-time-total {
					font-size: 11px;
					font-size: 1.1rem;
					color: $core-color;
				}
			}

			.player-progress-bar {
				position: relative;
				height: 5px;
				width: 100%;
				overflow: hidden;
				background-color: #d6dde2;
				border-radius: 5px;

				input[type="range"] {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 10;
					width: 100%;
					margin: 0;
					cursor: pointer;
					transform: translateY(-50%);
					opacity: 0;
				}

				.player-running-bar {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 0%;
					background-color: $core-color;
				}
			}
		}
	}
}