#comarquage{
    a{
        text-decoration: none;
    }
    & > a {
        &:after{
            display: none;
        }
    }
    .sommaire{
        display: flex;
        flex-wrap: wrap;

        a{
            margin-bottom: 10px;
            margin-right: 10px;

            &:last-child{
                margin-right: 0;
            }
        }
    }
    h2{
        .fa{
            margin-right: 10px;
        }
    }
    .grid-comarquage{
        clear: both;
        @include RWD(desktop){
            @include grid-distribution(3, 20, 'grid-item');                
        }
        @include RWD(tablet){
            @include grid-distribution(2, 20, 'grid-item');                
        }
        @include RWD(mobile){
            @include grid-distribution(1, 0, 'grid-item');                
        }

        /* Vignettes */
        .grid-item{
            margin-bottom: 30px;
            display: flex;

            & > div{
                flex: 1;
                display: flex;
                flex-direction: column;
                text-decoration: none;
                color: inherit;
                transition: all, 0.5s;
                padding: 30px 20px;
                background-color: #f7f7f7;
                border: solid 2px #F7F7F7;
                position: relative;
                
                .grid-item-title{
                    color: #2D2D2D !important;
                    font-family: $main-font;
                    font-weight: 600;
                    font-size: 2.5rem;
                    line-height: 1.2em;
                    
                    & + .grid-item-description{
                        margin-top: 15px;
                    }
                }
                .grid-item-description{
                    font-family: $main-font;
                    font-size: 1.6rem;
                    color: #595959;
                    letter-spacing: 0.05em;
                    font-weight: 400;
                    line-height: 1.2em;
                }
                a{
                    &:hover, &:focus{
                        text-decoration: underline;
                    }
                }
                .grid-item-text{
                    padding: 20px !important;
                }
            }
        }
    }
}