.m-form-filter{
    display: flex;
    flex-wrap: wrap;

    .form__group{

        &:nth-child(2n){
            width: 50%;
            padding-left: 38px;
            @include RWD(mobile){
                width: 100%;
                padding-left: 0;
            }
        }

        &:nth-child(2n+1){
            width: 50%;
            padding-right: 38px;

            @include RWD(mobile){
                width: 100%;
                padding-right: 0;
            }
        }
    }
    .form__label{
        width: 100%;
        justify-content: flex-start;
        margin: 0;
        margin-bottom: 10px;
    } 
    .form__field{
        width: 100%;
        margin: 0;

        input{
            width: 100%;
        }
    }
    .form__infos{
        margin-left: 0;
    }
    .form__actions{
        width: 100%;
    }
}
