.a-iframe{
    position: relative;
    padding-bottom: 57%;
    overflow: hidden;

    iframe{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
    }
}