.banner {
	@include fadeIn();
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 835px;
	position: relative;

	@include RWD(tablet) {
		height: 575px;
	}

	@include RWD(mobile) {
		height: 785px;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.2) 24%, rgba(0, 0, 0, 0.33) 72%, rgba(0, 0, 0, 0.43) 100%);
		z-index: 1;
	}

	& > * {
		z-index: 2;
	}

	.container-flex {
		display: flex;
		position: relative;
		height: 100%;

		.banner__card {
			height: 400px;
			width: 800px;
			background: $page-bg-color;
			opacity: 0.8;
			position: absolute;
			z-index: 5;
			top: 435px;
			right: 0;
			align-items: flex-end;

			@include RWD(tablet) {
				width: 100%;
				height: 335px;
				top: 245px;
			}

			@include RWD(mobile) {
				height: 385px;
				top: 400px;
				padding-left: 20px;
				padding-right: 20px;
			}

			.banner__cardtitle {
				padding-top: 80px;
				padding-left: 55px;
			}

			.h1 {
				overflow-wrap: break-word;
				/*color: $core-color-font;*/
				font-size: 80px;
				//font-family:$fancy-font;

				line-height: 70px;

				@include RWD(tablet) {
					padding-left: 50px;
					font-size: 50px;
					padding-top: 80px;
				}

				@include RWD(mobile) {
					padding-top: 55px;
					padding-left: 0px;
					padding-right: 0px;
					font-size: 40px;
					line-height: 40px;
				}
			}

			.banner__carddescription {
				font-size: 18px;
				font-family: $main-font;
				padding-left: 120px;
				padding-top: 55px;
				color: $core-color-font;

				@include RWD(tablet) {
					padding-left: 150px;
					padding-top: 5px;
					padding-right: 30px;
				}

				@include RWD(mobile) {
					padding-left: 45px;
					padding-right: 30px;
					line-height: 30px;
					padding-top: 25px;
				}
			}

			.a-btn-main {
				padding-top: 0px !important;
				padding-bottom: 0px !important;
				padding-left: 0px !important;
				padding-right: 0px !important;
			}

			.banner__cardbutton {
				margin-left: 115px;
				margin-top: 10px;

				@include RWD(tablet) {
					margin-left: 150px;
					margin-top: 25px;
				}

				@include RWD(mobile) {
					margin-left: 40px;
					padding-right: 15px;
					font-size: 20px;
					margin-top: 25px;
				}
			}

			.banner__cardbuttontext {
				padding-top: 0px !important;
				padding-bottom: 0px !important;
				padding-left: 25px !important;
				padding-right: 25px !important;
				font-size: 20px;

				@include RWD(mobile) {
					padding-left: 20px !important;
					padding-right: 20px !important;
				}
			}
		}

		.banner__card::after {
			content: "";
			background-color: $page-bg-color;
			height: 400px;
			width: 5000px;
			position: absolute;
			bottom: 0;
			left: 800px;
			z-index: 5;

			@include RWD(tablet) {
				display: none;
			}
		}

		.banner__logo {
			color: $second-color-font;
			line-height: 60px;
			line-height: 50px;
			margin-top: 50px;
			margin-left: 20px;
			position: relative;
			justify-self: start;

			@include RWD(tablet) {
				margin-top: 30px;
				line-height: 40px;
			}

			@include RWD(mobile) {
				margin-top: 115px;
				padding-left: 5px;
				font-size: 40px;
			}

			.banner__logo-bold {
				font-size: 60px;
				font-weight: 700;

				@include RWD(tablet) {
					font-size: 40px;
				}
			}

			.banner__logo-regular {
				font-size: 40px;

				@include RWD(tablet) {
					font-size: 30px;
				}
			}

			.banner__logo-chatou {
				font-size: 20px;
				font-family: $fancy-font;
				transform: rotate(-90deg);
				position: absolute;
				left: -55px;
				top: 175px;

				@include RWD(tablet) {
					top: 120px;
				}

				@include RWD(mobile) {
					top: 105px;
				}
			}
		}

		.arrow {
			position: absolute;
			bottom: 120px;
			left: -10px;
			flex-shrink: 0;
			content: '';
			display: inline-block;
			width: 25px;
			height: 25px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(svg-bg-uri($svg-arrow1, $core-color-font));
			transform: rotate(90deg);
			z-index: 10;
			transition: all 0.5s ease-in-out;
			cursor: pointer;


			@include RWD(tablet) {
				bottom: 110px;
			}

			@include RWD(mobile) {
				display: none;
			}
		}
	}

	.banner__arrow-line {
		width: 1px;
		height: 90px;
		background: $core-color;
		margin-left: 0px;
		margin-right: 0px;
		position: absolute;
		left: 0px;
		bottom: 0;
		border: 1px solid $core-color;
		transition: all 0.5s ease-in-out;
        cursor: pointer;


		@include RWD(tablet) {
			z-index: 10;
			margin-bottom: 0px;
			// margin-top:0px;
		}

		@include RWD(mobile) {
			display: none;
		}

	}

}

.banner__arrow-container {
	position: absolute;
	left: 35px;
	bottom: 0;

	&:hover {
		.arrow {
			position: absolute;
			bottom: 130px;
			left: -10px;
			flex-shrink: 0;
			content: '';
			display: inline-block;
			width: 25px;
			height: 25px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(svg-bg-uri($svg-arrow1, $core-color-font));
			transform: rotate(90deg);
			z-index: 10;
			transition: all 0.5s ease-in-out;
		}
	}

	@include RWD(tablet) {
		left: 35px;
	}
}