.#{$sfull_name} {
	&-thumbnail {
        position:relative;
		margin-top: 40px;

		@include RWD(mobile) {
			margin-top: 20px;
		}

		/* ITEM
        ============================= */
		&__nav {
			position: absolute;
            bottom: 0px;
            width:100%;
		}

		&__slider-item {
			position: relative;
			height: auto;
			padding-bottom: 57%;
		}

		&__slide {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			cursor: pointer;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
				height: 100%;
				width: 100%;
				background-color: rgba(#000000, 0.5);
				background-image: url(svg-bg-uri($svg-morecircled, #FFFFFF));
				background-position: center;
				background-size: 40px 40px;
				background-repeat: no-repeat;
				opacity: 0;
				transition: all, 0.5s;
			}

			&:hover,
			&:focus {
				&:before {
					opacity: 1;
				}
			}

			&--video {
				&:before {
					display: block;
				}

				.owl-video-tn {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
				}

				.owl-video-play-icon {
					display: none;
				}

				.owl-video-wrapper {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;

					&:before {
						display: none;
					}
				}
			}
		}


		/* ARROWS
        ============================= */

		&__arrow {
            position:absolute;
			@include RWD(mobile) {
				display: none;
			}


			&-icon {
                &--prev{
                    transform: rotate(-180deg);
                }
                &--next{
                    //transform: translateX(5px);
                }
            }

			&-pagination {
				display: none;
			}

			&--prev {
				height: 50px;
				background: $page-bg-color;
				opacity: 0.8;
                left: 35px;
                width:50px;
                border-radius:100%;
                //display:flex;
			}

			&--next {
				height: 50px;
				background: $page-bg-color;
				opacity: 0.8;
                right: 35px;
                width:50px;
                border-radius:100%;
            }
            
            &-container{
                &--relative{
                    width:100%;
                    height:220px;
                    @include RWD(tablet){
                        height:145px;
                    }

                }
                &--absolute{
                    width:100%;
                    height:100%;
                }
            }

			&--total {}
		}

		/* Pager
        ============================= */

		&__pager {
			&--current {}

			&--prev {}

			&--next {}
		}


		/* DOTS
        ============================= */

		&__dot {


			&.active {}
		}

		/* Playpause
        ============================= */

		&__playpause {
			display: none;

			&.play {}

			&.pause {}
		}

		/* Slider Text
        ============================= */

		&__text {
			display: none !important;
		}
	}
}