/**
    Layout entourant les médias pour une présentation avec Titre/description/transcription
    On part d'un layout général: m-mediabox, auquel on applique des modifiers en fonction du medias
*/
.m-mediabox{
    &__body{
        padding: 20px;
        background-color: #f7f7f7;
    }

    &__content{
        display: block;
    }

    &__ratio{
        width: 100%;
        margin-bottom: 20px;
    }

    &__actions{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__heading{
        padding-left: 40px;
        margin: 0;
        @extend .h3;
        & + .m-mediabox__description{
            margin-top: $mt-h3;
        }
    }

    &__description{
        padding: 0 40px;
        @include RWD(mobile){
            padding: 0 20px;
        }
        & > *:last-child{
            margin-bottom: 0;
        }
    }

    &__transcription-button{
    }

    &__transcription{
        width: 100%;
        display: none;
        padding: 60px;
        background-color: #f7f7f7;

        @include RWD(tablet){
            padding: 20px;
        }

        > *:last-child{
            margin-bottom: 0;
        }
    }

    // Audio
    &--audio-player{
        .m-mediabox__content{
            display: flex;
            flex-wrap: wrap;
        }

        .m-mediabox__ratio{
            margin-top: 30px;
            text-align: center;

            audio{
                max-width: 100%;
            }
        }
    }
    
    // Iframe
    &--iframe{
        .m-mediabox__body{
            padding: 0;
        }
        .m-mediabox__text{
            padding: 20px;
        }
        .m-mediabox__actions{
            padding: 20px;
            padding-top: 0;
        }
    }
    
    // Download
    &--download{ 
        position: relative;

    
        .m-mediabox__top-line{
            display: flex;
            flex-wrap: wrap;
    
            @include RWD(mobile){
                display: block;
            }
        }
        .m-mediabox__text{
            flex: 1;
        }
        
        .m-mediabox__visu{
            height: 130px;
            width: 125px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 20px;
            @include RWD(mobile){
                margin-bottom: 20px;
                margin-left: auto;
                margin-right: auto;
            }
    
            img{
                max-height: 100%;
                max-width: 100%;
            }
        }
        .m-mediabox__ratio{
            width: auto;
            margin-bottom: 20px;
            text-align: right;
    
            @include RWD(mobile){
                margin-top: 20px;
            }
            
        }
    }

    // Gmap
    &--gmap{
        .m-mediabox__body{
            padding: 0;
        }
        .m-mediabox__text{
            padding: 20px;
        }
        .m-mediabox__ratio{
            position: relative;
            overflow: hidden;
            margin-bottom: 0;
        }
    }
}