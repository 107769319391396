.propositions {
	margin-top: 100px;
	width: 100%;

	&-titre {
		text-transform: uppercase;
		font-size: 4rem;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			bottom: 0px;
			height: 2px;
			background-color: $core-color;
			width: 100%;
		}
	}

	&-content {
		//height:auto;
		height: auto;
		width: 100%;
		margin-top: 55px;
		display: flex;
        flex-wrap: wrap;
        position:relative;
		//justify-content: space-between;

		&-card {
            width: calc((100% / 3) - 35px);
            position: relative;           
            height: 310px;           
            margin-right: 45px;
            &:nth-child(3n){
                margin-right:0px;
            }

			@include RWD(tablet) {
                //width: calc((100% / 2) - 60px);
                width:calc((100% / 2) - 25px);
                margin-right:35px;
                &:nth-child(3n){
                    margin-right:35px;
                }

                &:nth-child(2n){
                    margin-right:0px;
                }
			}

			@include RWD(mobile) {
                height: 300px;
                width:100%;
                margin-right:0px;
			}


			&-visu {
				height: 200px;
				width: 200px;
				background-color: $core-color;
				position: absolute;
				left: 0px;
				top: 0px;

				// @include RWD(tablet) {
				// 	height: 60%;
				// 	width: 50%;
				// 	min-width: 300px;
				// 	padding-bottom: 55%;
				// }

			}

			&-infos {
				background-color: #f7f7f7;
				position: absolute;
				width: 300px;
				height: 165px;
				left: 85px;
				top: 100px;
				padding-left: 20px;
				line-height: 30px;
				padding-right: 10px;

				@include RWD(tablet) {
					width: 80%;
					left: 55px;
                }
                @include RWD(mobile){
                    // min-height:165px;
                    // height:auto;
                    padding-bottom:10px;
                }



			}

			&-type,
			&-title {
				color: #303030;
				font-size: 1.8rem;
			}

			&-date {
				font-size: 2rem;
				font-family: $fancy-font;
				color: $core-color;
                margin-top: 10px;
                @include RWD(mobile){
                    font-size:1.8rem;
                }
			}

			&-hour {
				color: #505050;
				font-size: 1.8rem;
			}
		}
	}
}