.not-front .view-list,
.not-front.page-search-site{
    .view-filters{
        margin-bottom: 40px;
    }
    .view-header{
        margin-bottom: 20px;
    } 
    .view-geoloc{
        margin-bottom: 20px;
    }
    .pagination{
        margin-top: 40px;
    }
}