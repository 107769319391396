#facets{
    h2{
        margin-bottom: 10px;
        display: block;
    }
    .facets-list{
        display: flex;
        flex-wrap: wrap;
    }

    .block-facetapi{
        margin-bottom: 30px;
        
        & + .block-facetapi{
            margin-left: 20px;
        }

        @include RWD(mobile){
            flex: 0 0 100%;
            margin: 0;
            margin-bottom: 20px;
        }
    }

    .toggle-facets{
        display: flex;
        align-items: center;
        position: relative;
        padding: 10px 20px;
        border: solid 1px #d0d0d0;
        color: $text-color;
        width: 100%;

        &:after{
            margin-top: -4px;
        }
    }
    ul{
        padding-left: 20px;
    }
    
    .facetapi-facetapi-links{ 
        border: 1px solid #d0d0d0;
        border-top: none;
        padding: 10px 20px;
        display: none;
        list-style: none;

        li{
            &:before{
                display: none;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
        a{
            text-decoration: none;
            &:hover, &:focus{
                text-decoration: underline;
            }
        }

        // Niveau 1
        & > li {
            font-weight: 600;
        }

        // Niveau 2
        .item-list{
            margin-top: 5px;
            li{
                font-weight: 400;
            }
        }

        // Li actives
        .facetapi-active{
            color: $second-color;
        }

        // Li inactives
        .facetapi-inactive{
            color: #333333;
        }
    }

    // Style actif
    .block-facetapi.active{
        .toggle-facets{
            border-color: $second-color;
            color: $second-color;
            &:after{
                border-color: $second-color;
            }
        }
        .facetapi-facetapi-links{
            border-color: $second-color;
        }
    }
}