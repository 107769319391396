@mixin fadeIn {
	opacity: 0;

	&[data-animation-state="active"] {
		animation: fadeIn 1.5s forwards; //forwards =  l'element garde le css donné a la fin de l'animation (last frame de l'anim -> reste active)
	}

	@keyframes fadeIn {
		to {
			opacity: 1;
		}
	}
}

@mixin linkHover($color, $size) {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: -3px;
		height: #{$size}px;
		width: 0;
		background: $color;
		transition: 0.2s;
		animation: linkHoverIntro .2s forwards;

		@keyframes linkHoverIntro {
			to {
				width: 100%;
			}
		}
	}
}