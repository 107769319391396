.a-input-text{
    width: 100%;
    height: #{$form-el-height}px;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    border-radius: 0;
    font-size: 1.6rem;
    font-family: $main-font;
    color: $text-color;
    line-height: 1.2em;
    padding: 0 15px; 
    -webkit-appearance: none;

    &:focus{
        border-color: $core-color;
        outline: none;
    }
} 