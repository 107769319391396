$path: '../fonts/';

  // Rozha One
  @font-face {
      font-family: 'Rozha One';
      src: url('#{$path}Rozha-One.woff') format('woff');
      font-weight: 400;
      font-style: normal;
  }

  @font-face {
      font-family: 'Roboto';
      src: url('#{$path}Roboto-700.woff') format('woff');
      //bold
      font-weight: 700;
      font-style: normal;
  }

  @font-face {
      font-family: 'Roboto';
      src: url('#{$path}Roboto-400.woff') format('woff');
      //regular
      font-weight: 400;
      font-style: normal;
}

//ENDFONTS

