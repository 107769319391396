html{
    font-size: 62.5%;
    background-color: $page-bg-color;

    body{
        overflow-x: hidden;
        @extend %text-basis;
    }

    &.no-scroll{
        height: 100%;
        overflow: hidden;
    }
    &.overlayed{

        &:before{
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
            background-color: rgba(#000000, 0.8);
        }
    }
}
#main-content{
    overflow: hidden;
}

// Selection de texte
::selection{
    background-color: rgba($color: $core-color, $alpha: 0.99);
    color: $core-color-font;
}
::-moz-selection{
    background-color: $core-color;
    color: $core-color-font;
}

// Placeholders
::-webkit-input-placeholder {
    opacity: 1 !important;
}
:-moz-placeholder{ 
    opacity: 1 !important;
} 
::-moz-placeholder { 
    opacity: 1 !important;
}
:-ms-input-placeholder {  
    opacity: 1 !important;
}

.element-invisible, .hidden{
    display: none;
}


// Option liste déroulante de Select2 js qui est crée en dehors du scope du module (apres le footer)
.select2-results__option{
    font-size: 1.4rem;
}