.a-radio{
    position: absolute;
    opacity: 0;
    & + label{
        display: flex;
        align-items: center;
        &:before{
            content: '';
            display: block;
            height: 15px;
            width: 15px;
            border: solid 2px $core-color;
            background-color: transparent;
            border-radius: 50%;
            margin-right: 13px;
            flex-shrink: 0;
        }
    }
    &:checked + label{
        &:before{
            background-color: $core-color;
        }
    }
}