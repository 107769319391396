/* Configuration
============================= */

$sfull_has_overlay: true;
$sfull_animation_time: 1000ms;
$sfull_name: 'slider-full';
.#{$sfull_name}{
    &-main,
    &-thumbnail, 
    &-slideNpop{
        position: relative;

        /* ITEM
        ============================= */

        &__slider-item {
            height: 500px;
            position: relative;
        }
        &__slide{
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @include RWD(tablet){
                height: auto;
                padding-bottom: 57%;
            }
            @if ($sfull_has_overlay) {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    height: 100%;
                    width: 100%;
                    background-color: rgba(#000000, 0.5);
                }
            }
            &--video {
                @if($sfull_has_overlay) {
                    .owl-video-wrapper {
                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 2;
                            height: 100%;
                            width: 100%;
                            background-color: rgba(#000000, 0.5);
                        }
                    }
                }
                .owl-video-wrapper{
                    position: absolute;
                    top: 0; left: 0;
                    height: 100%;
                    width: 100%;
                }
                &:before {
                    display: none;
                }
                .owl-video-play-icon {
                    z-index: 3;
                    background-image: url(svg-bg-uri($svg-player, #FFFFFF));
                }
            }
        }

        /* ARROWS
        ============================= */

        &__arrow {
            position: absolute;
            top: 50%;
            z-index: 20;
            overflow: visible;
            transform: translateY(-50%);

            .flexbox {
                position: relative;
                height: 50px;
                width: 50px;
                overflow: visible;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $core-color;
            }
            &-icon {
                content: '';
                height: 20px;
                width: 20px;
                display: inline-block;
                background-image: url(svg-bg-uri($svg-map-chevron, $core-color-font));
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                flex-shrink: 0;
            }
            &--prev &-icon {
                transform: rotate(-180deg);
            }
            &-pagination {
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $second-color;
                color: $second-color-font;
                opacity: 0;
                transition: opacity .3s;
                &:after {
                    content: '/';
                    position: absolute;
                    top: 50%; left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            &--prev {
                left: 0;
            }
            &--prev &-pagination {
                right: 0;
                transform: translateX(100%);
            }
            &--next {
                right: 0;
            }
            &--next &-pagination {
                left: 0;
                transform: translateX(-100%);
            }
            &--total {}
            &:hover &-pagination, &:focus &-pagination{
                opacity: 1;
            }
        }

        /* Pager
        ============================= */

        &__pager {
            font-size: 1.7rem;
            font-weight: 700;
            &--current {
                display: none;
            }
            &--total--slideNpop{
                display: none;
            }
            &--prev {
                
            }
            &--next {
            }
            &--next, &--prev{
                position: absolute;
                top: 10px;
                left: 10px;
            }
            &--total{
                position: absolute;
                right: 10px;
                bottom: 10px;
            }
        }

        /* DOTS
        ============================= */

        &__dots {
            position: absolute;
            left: 50%;
            bottom: 40px;
            z-index: 2;
            transform: translateX(-50%);
        }
        &__dot {
            height: 20px;
            width: 20px;
            background-color: #FFFFFF;
            font-size: 0; // Fait disparaître les chiffres dans les cercles
            border-radius: 50%;
            border: solid 2px $core-color;
            &.active {
                background-color: $core-color;
            }
            & + & {
                margin-left: 5px;
            }
        }

        /* Playpause
        ============================= */
        
        &__playpause {
            content: '';
            position: absolute;
            right: 40px;
            bottom: 40px;
            z-index: 2;
            height: 20px;
            width: 20px;
            display: inline-block;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            flex-shrink: 0;
            &.play {
                background-image: url(svg-bg-uri($svg-play, #FFFFFF));
            }
            &.pause {
                background-image: url(svg-bg-uri($svg-pause, #FFFFFF));
            }
        }

        /* Slider Text
        ============================= */

        &__text {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            color: #FFFFFF;
            transform: translate(-50%, -50%);
            transition: all 0.5s;
            max-width: calc(100% - 80px);
        }
        &__title {}
        &__description {}
        &__link {
            color: #FFFFFF;
        }

        /* Animation, loader et différents états
        ============================= */

        /* -- Loader -- */
        &:not(.loaded){
            overflow: hidden;
            max-height: 400px;
        }
        .loader{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 30;
            height: 100%;
            width: 100%;
            background-color: #FFFFFF;
            background-color: #FFFFFF;
            border: solid 2px $core-color;        
        }
        &.loaded .loader{
            display: none !important;
        }

        /* -- Slides -- */

        .owl-item {
            @keyframes customIn {
                0% {
                    transform: translateX(100px);
                    opacity: 0;
                }
                100% {
                    transform: translateX(0);
                    opacity: 1;
                }
            }
            @keyframes customOut {
                0% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                }
            }
            &.active {}
            &.animated {
                animation-duration: 1s;
            }
            &.customIn {
                animation-name: customIn;
            }
            &.customOut {
                animation-name: customOut;
            }
        }

        /* -- Texte -- */

        &.animating &__text {
            transform: translate(-50%, -50%) translateY(-40px);
            opacity: 0;
        }

        /* Elements disabled
        ============================= */
        
        &.no-autoplay &__playpause{display: none !important;}
        &.no-text &__text{display: none !important;}
        &.no-dot &__dots{display: none !important;}
        &.no-pager &__arrow-pagination{display: none !important;}
        &.no-arrow &__arrow{display: none !important;}
    }
    
}