.m-grid-teaser-trombi{
    @include RWD(tablet){
        margin-bottom: 20px;
    }    
    @include RWD(desktop){
        @include grid-distribution(2, 40, 'm-grid-teaser-trombi__item');
    }
    @include RWD(tablet_only) {
        @include grid-distribution(2, 20, 'm-grid-teaser-trombi__item');
    }

    &__item{
        margin-top: 40px;

        @include RWD(tablet){
            margin-top: 30px;
        }

        &:nth-child(1), &:nth-child(2){
            @include RWD(desktop){
                margin-top: 0;
            }
        }
    }
}