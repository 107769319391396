.select2{
    // Attention, la liste déroulante est crée en JS après le footer, modifiable dans page-global.scss
    @extend .a-input-text;
    height: auto;
    min-height: #{$form-el-height}px;
    // padding: 0 15px;
    display: flex;
    align-items: center;
    &.select2-container--focus{
        border-color: $core-color;
    }

    &:after { 
        position: absolute;
        top: 50%;
        right: 15px;
        content: '';
        display: block;
        height: 20px;
        width: 20px;
        background-size: 15px;
        background-position: center;
        background-repeat: no-repeat;
        transform:  translateY(-50%) rotate(90deg);
        background-image: url( svg-bg-uri($svg-arrow1, #7e7e7e) );
    }
    .selection{
        width: 100%;
    }
    .select2-selection{
        background-color: transparent;
        width: 100%;
        padding-right: 40px;
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        border: none !important;
    }
    .select2-selection__rendered{
        padding: 0 !important;
        margin-bottom: 0;
    }
    .select2-selection__rendered li{
        display: flex;
        align-items: center;
        height: 25px;
        font-family: $fancy-font;
        color: $text-color;
        font-size: 1.4rem;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 1.2;
        margin-bottom: 5px;

        &:before{
            display: none;
        }
        &.select2-search--inline{
            padding-top: 5px;
        }
    }
    .select2-search{
        width: 100%;
    }
    .select2-search__field{
        margin: 0 !important;
        height: 100% !important;
        padding: 0!important;
        min-height: 0;
        width: 100% !important;
        border: none !important;
        &:hover, &:active, &:focus{
            border-color: transparent !important;
            background-color: transparent !important;
        }
    }
    // Quand une réponse est renseignée on cache le champ de recherche, il ne sert que pour le placeholder
    .select2-selection__choice + .select2-search{
        display: none;
    }
}
.toValidate{
    .valid + .select2-container{
        border-color: $success-color !important;
    }
    .error + .select2-container{
        border-color: $error-color !important;
    }
}