.a-link-group {
	width: 600px;
	max-width: 100%;

	li {
		a {
			transition: all, 0.5s;

			&:hover,
			&:focus {
				background-color: $core-color !important;
				color: $core-color-font;

				&:before {
					background-image: url(svg-bg-uri($svg-arrow1, $core-color-font));
				}
			}
		}

		&:nth-child(2n + 1) {
			a {
				background-color: #f7f7f7;
			}
		}
	}

	&__item {
		display: flex;
		align-items: center;
		padding: 20px;
		color: $core-color;
		text-decoration: none;
		@extend .h3;

		&:before {
			content: '';
			height: 20px;
			width: 30px;
			display: inline-block;
			margin-right: 20px;
			background-image: url(svg-bg-uri($svg-arrow1, $core-color));
			background-position: center;
			background-size: auto;
			background-repeat: no-repeat;
			transition: .5s;

			@include RWD(mobile) {
				height: 10px;
				width: 20px;
			}
		}

		span {
			display: none;
		}
	}
}