.m-generic-form{
    $gutter-y: 20px;

    .a-disclaimer{
        margin-bottom: $gutter-y;
    }

    .form__group{
        display: block;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: $gutter-y;
        &:last-child{
            margin-bottom: 0;
        }
        &.cnil{
            margin-top: 20px;
        }
        &.webform-flexbox{
            display: flex;
        }
    }

    .form__label{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        &.visually-hidden{
            margin: 0;
        }
    }
    .form__field{
        display: flex;
        flex-direction: column;
        position: relative;
    }
    
    .a-form-infos{
        width: 100%;
        margin-top: 5px;
    }

    // Inputs à la suite
    &:not(.m-generic-form--sbs) .form__inline-box{
        display: flex;
        margin-bottom: $gutter-y;

        .form__group{
            width: 100%;
            margin-bottom: 0;
            & + .form__group{
                margin-left: 120px;
            }
        }

        @include RWD(mobile){
            display: block;
            .form__group{
                width: 100%;

                & + .form__group{
                    margin-left: 0;
                }
            }
        }
    }

    // Version drupal
    .webform-flexbox{
        margin-bottom: $gutter-y;
        margin-top: 0;

        &:last-child{
            margin-bottom: 0;
        }
    }

    .form__actions{
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > * {
            margin-left: 20px;
        }

        @include RWD(mobile){
            //flex-direction: column;
            flex-direction:row;
            align-items: flex-end;
            justify-content: space-around;

            & > *{
                margin-left: 0;

                & + * {
                    margin-top: $gutter-y;
                }
            }
        }
    }

    .form__footer{
        margin-top: 30px;
        
        &:before{
            display: block;
            content: ''; 
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30px;
            height: 1px;
            background-color: #d8d8d8;
            width: 80%;
        }
    }


    @include RWD(mobile){
        .form__group{
            display: block;
        }
        .form__label{
            width: 100%;
            margin-right: 0;
            margin-bottom: #{$gutter-y / 2};
            display: block;
        }
        .form__field{
            width: 100%;
            margin-left: 0;
        }
    }

    // Validation    
    .error{
        border-color: $error-color !important;
    }
    span.error{
        color: $error-color;
        margin-top: 5px;
    }
    .valid{
        border-color: $success-color !important;
    }
    &--sbs{
        .form__inline-box{
            margin-bottom: $gutter-y;
        }
        @include RWD(tablet_desktop){
            .form__group{
                display: flex;
                &--radio, &--checkbox{
                    .form__field{            
                        padding : 15px 20px 15px 20px;
                        flex-flow : row wrap;
                        background-color: #F4F4F4;
                        border-radius : 5px;
                    }
                    .form__item + .form__item{
                        margin-left: 20px;
                    }
                    .error{
                        width: 100%;
                        margin-top: 12px;
                    }
                }
            }
            .form__label{
                width: 20%;
                padding : 2px 20px 5px 0;
                margin-bottom: 0;
                flex-shrink: 0;
            }
            .a-form-infos{
                padding-left: 20%;
            }
            .a-fancy-file{
                .a-form-infos{
                    padding-left: 0;
                }
            }
            .form__field{
                width : 80%;
                flex-shrink: 0;
            }            
        }
    }
    .js-form-item {
        &[class*="other"] {
            .form__field {
                display: block;
            }
        }
    }
}