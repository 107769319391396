$svg-gmap-head: $svg-marker-circled;

.a-infowindow{
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    padding-top: 80px;
    @include RWD(mobile){        
        padding: 20px;
        padding-top: 40px;
        text-align-last: center;
    }


    &__content{
        height: 100%;
        overflow: auto;
    }
    &__icon{
        margin-bottom: 25px;
        content: '';
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-gmap-head, $core-color) );
        @include RWD(mobile){
            display: none;
        }
    }
    &__name{
        @extend .h3;
        margin-bottom: 20px;
    }
    &__title{
        @extend .h3;
        margin-bottom: 25px;
        padding-left: 0 !important;
    }
    &__address{
        margin-bottom: 20px;
        label{
            display: block;
            font-weight: 700;
        }
    }
    &__desc{
        margin-bottom: 20px;
    }
    &__close{
        cursor: pointer; 
        position: absolute;
        top: 25px;
        right: 35px;
        content: '';
        display: inline-block;
        width: 25px;
        height: 25px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-close, rgba(#2d2d2d,0.5)) );
        opacity: 1;
        transition: all, 0.5s;

        @include RWD(mobile){
            top: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
        }

        &:hover{
            opacity: 0.5;
            // &:after, &:before{
            //     background-color: #2D2D2D;
            // }
        }
    }
}

.a-gmap{
    
    position: relative;
    overflow: hidden;
    .map{
        height: 500px;
        @include RWD(mobile){
            height: 300px;
        }
    }
    .a-infowindow{
        position: absolute;
        top: 0; right: 0;
        height: 100%;
        width: 400px;
        max-width: 100%;
        transform: translateX(100%);
        transition: all, 0.5s;
        z-index: 500;
        @include RWD(mobile){
            width: 100%;
        }
        &.opened{
            transform: translateX(0);
        }
    }
    .markerLabel{
        @extend .a-edito-inlines;
        width: 15px;
        height: 15px;
        color: #FFFFFF;
        display: flex !important;
        align-items: center;
        justify-content: center;
        transform: translate(-5px, -3px);
    }
}