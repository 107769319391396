.user-alert-banner {
	background-color: #dadada;
	position: relative;
	height: 80px;
	padding: 15px 20px;
	padding-right: 40px;
	text-decoration: none;
	display: flex;
	align-items: center;

	@include RWD(tablet) {
		padding-right: 50px;
		padding-left: 50px;
		//align-items: flex-start;
	}

	@include RWD(mobile) {
		padding-left: 20px;
		padding-right: 20px;
		//padding-top: 20px;
		height: auto;
	}

	& > *,
	a {
		color: $core-color-font;
	}

	.container {
		@include RWD(tablet) {
			padding: 0 !important;
		}
	}

	.user-alert-left {
		display: flex;
        align-items: baseline;
		width:100%;
		@include RWD(tablet){
			align-items:start;
		}
		@include RWD(mobile){
			transform: translateY(-5px);
		}
	}

	.user-alert-right {

		@include RWD(tablet){
			height:25px;
		}
		@include RWD(mobile) {
			padding-left: 37px;
		}
	}

	.warning {
		flex-shrink: 0;
		content: '';
		display: inline-block;
		width: 17px;
		height: 15px;
		background-size: 17px;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(svg-bg-uri($svg-danger-stroked, $core-color-font));

		@include RWD(mobile) {
			top: 10px;
			transform: none;
		}

		@include RWD(tablet) {}
	}

	.user-alert-message {
		@extend %text-basis;
		//font-weight: 300;
		font-family: $fancy-font;
		font-size: 1.1rem;
		color: $core-color-font;
		width: 100%;
		display: flex;
		align-items: baseline;
		text-decoration: none;
		position: relative;

		@include RWD(mobile) {
			display: block;
		}

		@include RWD(tablet) {
			display: flex;
		}
	}

	.user-alert-title {
		color: $core-color-font;
		text-transform: uppercase;
		font-size: 2.2rem;
		line-height: 1.8em;
		//padding-left: 30px;
		font-weight: 700;
		font-family: $fancy-font;

		@include RWD(tablet){
			transform: translateY(-9px);
		}

		@include RWD(mobile) {
			padding-left: 0;
			font-size: 2.2rem;
			width: calc(100% - 60px);
			display: block;
		}

		@include RWD(tablet) {
			width: auto;
		}
	}

	.user-alert-body {
		color: $core-color-font;
		font-size: 1.6rem;
		line-height: 1.2em;
		padding-left: 4px;
		flex: 1;
		font-family: $fancy-font;

		@include RWD(tablet) {
			padding-right: 40px;
			width: auto;
			margin-left: 30px;
		}

		@include RWD(mobile) {
			font-size: 1.3rem;
			width: 100%;
			//margin-top: 5px;
			padding: 0;
			margin-left: 20px;
		}

		& div {
			display: flex;
			align-items: center;
			flex-direction: row;
			margin-left: 30px;

			@include RWD(tablet) {
				margin-left: 0px;
            }
            @include RWD(mobile){
                align-items: start;
                flex-direction: column; 
            }

			& p {
				margin-left: 3px;
				font-family: $main-font; 
                font-size: 1.8rem;
				//display:flex;
				display:inline;
                align-items:center;
				transform: translateY(1px);
				@include RWD(tablet){
					width:50%;
					top:10px;
				}
                @include RWD(mobile){
                    flex-direction:column;
                    align-items: start;
                    font-size:1.6rem;
					margin-right:45px;
					width:90%;
                }
			}
		}
	}

	.user-alert-message {
		@include RWD(mobile) {
			display: flex;
			flex-direction: column;
		}
	}

	.user-alert-body {
		display: flex;
		font-family: $fancy-font;
	}

	.user-alert-more {
        text-decoration:none;
		& p {
			position: absolute;
			//top: 50%;
			top: 20px;
			transform: translateY(-50%);
			right: 45px;
			font-family: $fancy-font;
			color: $core-color-font;
			font-size: 1.6rem;
			line-height: 1.8em;
			//padding-left: 20px;
			font-weight: 700;
			font-family: $fancy-font;

			@include RWD(mobile) {
				position: static;
				padding-top: 35px;
			}
		}
	}

	.close {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0px;
		transition: all, 0.5s;
		flex-shrink: 0;
		margin-left: 20px;
		content: '';
		display: inline-block;
		width: 17px;
		height: 17px;
		background-size: 17px;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(svg-bg-uri($svg-close, $core-color-font));

		&:hover,
		&:focus {
			opacity: 0.7;
		}

		@include RWD(tablet){
			top:10px;
		}

		@include RWD(tablet) {
			top: 20px;
		}

		@include RWD(mobile) {
			top: 5px;
			transform: none;
		}
	}
}