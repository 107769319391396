/**
    slideNpop avec le même slider
    Au clic sur un li, ou change juste l'affichage en position fixed
*/

.#{$sfull_name}.same-slider {

	// 1 seul slider passé en fixed
	$slideNpopHeight: 500;

	position: relative;
	height: #{$slideNpopHeight}px;

	.#{$sfull_name}-main {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		&__slider-item {
			height: #{$slideNpopHeight}px;
		}

		&__close {
			display: none;
		}
	}


	.#{$sfull_name}-main.opened {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		background-color: $core-color;

		.#{$sfull_name}-main {
			&__slider {
				position: absolute;
				top: 50%;
				left: 50%;
				width: calc(100% - 200px);
				transform: translate(-50%, -50%);
			}

			&__slider-item {
				height: calc(100vh - 160px);
			}

			&__close {
				display: block;
			}
		}
	}
}