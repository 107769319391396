.agenda {
	//padding-top: 110px;
	height: auto;
	width: 100%;
	//    z-index: -1;
	position: relative;
	background-size: 3000px;
	background-repeat: no-repeat;
	background-position: top;
	background-color: rgba(255, 255, 255, 0.7);
	@include fadeIn();

	@include RWD(mobile) {
		display: flex;
		flex-direction: column;
		background-size: 5000px;
	}

	&-bg--overlay {
		height: 100%;
		width: 100%;
		background: rgba(255, 255, 255, 0.7);
		padding-top: 80px;
	}

	&__container {
		position: relative;
	}

	&__top {
		display: flex;
		align-items: baseline;
		justify-content: space-between;

		@include RWD(mobile) {
			flex-direction: column;
		}

		&__left {
			display: flex;
			align-items: baseline;

			@include RWD(mobile) {
				flex-direction: column;
			}

			&__titre {
				margin-right: 50px;
				font-size: 2rem;

				@include RWD(mobile) {
					&--content {
						margin-bottom: 30px;
						font-size: 4rem;
					}
				}
			}

			&__date {
				@include RWD(mobile) {
					&--content {
						margin-bottom: 30px;
					}
				}
			}
		}

		&__bouton {
			position: absolute;
			top: 0px;
			right: 0px;

			@include RWD(tablet) {
				left: calc(100vw - 280px);
				top:10px;
			}

			@include RWD(mobile) {
				position: static;
				margin: auto;
				text-align: center;
				margin-bottom:30px;
			}
		}

		&__switchcat {
			display: none;

			@include RWD(tablet) {
				display: block;
				margin-bottom: 65px;
			}

			@include RWD(mobile) {
				margin-bottom: 50px;
			}

			&__picto {
				flex-shrink: 0;
				content: '';
				display: inline-block;
				width: 17px;
				height: 17px;
				background-size: 17px;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url(svg-bg-uri($svg-arrow1, $core-color));
				transform: rotate(90deg) translateY(-18px);
				z-index: 10;

				@include RWD(tablet) {
					height: 45px;
				}
			}
		}
	}

	&__filtres {
		padding-top: 100px; // display:none;

		@include RWD(tablet) {
			display: none;
			width: 100%;
			position: absolute;
			background: $second-color-font;
			transform: translateY(-65px);
			padding-top: 20px;
			padding-bottom: 20px;
			z-index: 200;
		}

		@include RWD(mobile) {
			transform: translateY(-50px);
		}

		&__liste {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			font-size: 1.6rem;
			font-family: $main-font;

			@include RWD(tablet) {
				flex-direction: column;
			}

			& .is-active {
				position: relative;
				font-weight: 700;

				&:before {
					content: '';
					position: absolute;
					bottom: 4px;
					width: 100%;
					height: 2px;
					background: var(--bg-color);

					@include RWD(tablet) {
						display: none;
					}
				}

				@include RWD(tablet) {
					background: #cdcdcd;
					width: 100%;
				}
			}
		}

		&__item {
			color: $core-color-font;
			cursor: pointer;
			--bg-color: #c3916e;
			@include RWD(tablet) {
				padding-left: 25px;
			}
		}
	}

	&__cards {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;

		&__content {
			width: calc(50% - 50px);
			border-bottom: solid 2px $core-color;
			min-height: 280px;
			display: block;
			margin-right: 50px;
			margin-top: 30px;

			&:before {
				display: none;
			}

			@include RWD(tablet) {
				width: calc(50% - 25px);
				margin-right: 25px;
			}

			@include RWD(mobile) {
				width: 100%;
				margin-right: 0px;
				margin-top: 0px;
				margin-bottom: 45px;
			}

			&:nth-child(3) {
				margin-bottom: 80px;

				@include RWD(mobile) {
					margin-bottom: 45px;
				}
			}

			&:nth-child(4) {
				margin-bottom: 80px;
			}

			&--flexbox {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				&-top {
					display: flex;
					flex-direction: row;
					width: 100%;
					min-height: 185px;

					@include RWD(tablet) {
						flex-direction: column;
					}
				}

				&-bottom {
					display: flex;
					width: 100%;
					height: auto;
					align-items: flex-end;
					justify-content: flex-end;
					align-self: end;
				}
			}

			&__jour {
				margin-left: 45px;
				font-size: 2rem;
			}

			&__date {
				width: auto;
				display: inline-block;
			}

			&__numeros {
				&__jour {
					font-size: 6rem !important;
					width: auto;
					position: relative;
					display: block;
				}

				&__mois {
					font-size: 4rem;
					font-family: $fancy-font;
					color: $second-color;
					position: absolute;
					top: 20px;
					left: 30px;
				}
			}

			&__liste-event {
				margin-top: 25px;
				margin-left: 70px;

				@include RWD(tablet) {
					margin-left: 0px;
					margin-top: 50px;
				}
			}

			&__event {
				font-size: 1.8rem;
				color: $second-color;
				display: flex;
				line-height: 2rem;
				margin-top: 15px;

				&:nth-child(1) {
					margin-top: 0px;
				}

				&__nom {
					align-self: flex-end;
					width: 100%;
					display: flex;

					&:before {
						content: '';
						height: 2px;
						min-width: 40px;
						width: 40px;
						display: block;
						background: var(--bg-tiret);
						transform: translateY(-5px);
						align-self: flex-end;
						margin-left: 10px;
						margin-right: 10px;

						@include RWD(tablet) {
							width: 20px;
							&:before{
								align-self: center;
							}
						}
					}
				}
				&__horaires{
					align-self:flex-end;	
				}

				& a {
					display: flex;
					text-decoration: none;
					color: $second-color;
					font-size: 1.8rem;
				}

			}

			&__picto {
				height: 65px;
				width: 100%;

				&--more {
					z-index: 500;
					margin-bottom: 10px;
					flex-shrink: 0;
					content: '';
					display: inline-block;
					width: 30px;
					height: 30px;
					background-size: 25px;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url(svg-bg-uri($svg-morecircled, $core-color));
				}

				&--less {
					z-index: 500;
					margin-bottom: 10px;
					flex-shrink: 0;
					content: '';
					display: inline-block;
					width: 30px;
					height: 30px;
					background-size: 25px;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url(svg-bg-uri($svg-lesscircled, $core-color));
				}
			}

			&__numero {
				position: relative;
			}
		}
	}
}

.showDropdown {
	display: block;

	@include RWD(tablet) {
		width: 100%;
	}
}

.dropdown {
	position: relative;
	display: inline-block;
	width: 100%;

	@include RWD(tablet) {
		width: auto;
	}
}

//gestion des couleurs par catégorie d events

.agenda__filtres__item {
	&:hover {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			bottom: 4px;
			width: 100%;
			height: 2px;
			background: var(--bg-color);

			@include RWD(tablet) {
				display: none;
			}
		}

		@include RWD(tablet) {
			background: var(--bg-color);
			width: 100%;
		}
	}
}

