%head-infobox{
    font-family: $fancy-font;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 500;
    color: #212121; 
    line-height: normal;
}

%molecule-map-infobox{
    img{
        display: none;
    }
    .viewbox{
        padding: 20px;
        background-color: #FFFFFF;

        &:after{
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8px 0 8px;
            border-color: #FFFFFF transparent transparent transparent;
        }

        .info-head{
            position: relative;
            margin-bottom: 10px;
        }
            .info-title{
                @extend %head-infobox;
                padding-right: 40px;
                position: relative;
                top: -6px;

                &.info-back{
                    padding-left: 45px;
                    // @extend %icon-back-map; #TODO
                }
            }
            .info-close{
                position: absolute;
                top: 0;
                right: 0;
                // @extend %icon-close; #TODO
                transition: all, 0.5s;

                &:hover, &:focus{
                    opacity: 0.5;
                }
            }

        .info-content{
            font-family: $fancy-font;
            font-size: 15px;
            font-size: 1.5rem;
            color: $text-color;

            // Multiple
            .poi-list{
                padding-left: 0;  

                li{
                    display: flex;
                    align-items: center;
                    border-bottom: solid 1px #ebedf0;
                    margin: 0;
                    position: static; 
                    &:last-child{
                        border: none;
                    }
                    &:first-child{
                        border-top: solid 1px #ebedf0;
                    }
                    &:before{
                        display: none;
                    } 

                    button{
                        @extend %head-infobox;
                        padding: 10px 0;
                        text-align: left;
                        padding-right: 40px;
                        position: relative;
                        width: 100%;

                        .btn-text{
                            z-index: 1;
                            position: relative;
                        } 

                        &:after{
                            content: '';
                            background-image: url( svg-bg-uri($svg-map-chevron, $icon-color-active) );
                            height: 20px;
                            width: 30px;
                            background-position: right center; 
                            background-repeat: no-repeat; 
                            background-size: auto 13px;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                        }
                        &:before{
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0; left: -20px; right: -20px; bottom: 0;
                            background-color: #f5f5f5;
                            z-index: 0;
                            opacity: 0;
                            transition: all, 0.3s;
                        }

                        &:hover, &:focus{
                            &:after{
                                background-image: url( svg-bg-uri($svg-map-chevron, $core-color ) );
                            }
                            &:before{
                                opacity: 1;
                            }
                        }
                    }                 
                }
            }
        }
            .info-loc{
                & > :last-child{
                    margin-bottom: 0;
                }
            }
            .info-desc{

            }
        .info-links{
            margin: -20px;
            padding: 0 20px;
            margin-top: 0;
            background-color: #f5f5f5;
            display: flex;
        }
            #form-maps-button-append-itinerary{
                width: 50px;
                height: 50px;
                background-size: 30px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url( svg-bg-uri($svg-map-itinary, $icon-color-active) );

                &:hover, &:focus{
                    background-image: url( svg-bg-uri($svg-map-itinary, $core-color) );
                }
            }
            #form-maps-button-append-proximity{
                width: 50px;
                height: 50px;
                background-size: 30px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url( svg-bg-uri($svg-map-flag, $icon-color-active) );     

                &:hover, &:focus{
                    background-image: url( svg-bg-uri($svg-map-flag, $core-color) );
                }       
            }
    }
}