.#{$sfull_name}.slider-slideNpop{
    .#{$sfull_name}-main{
        &.opened{
            .#{$sfull_name}-main{
                /* -- Pager -- */
                &__pager{
                    &--current{
                        display: block;
                        position: absolute;
                        right: 40px;
                        bottom: 20px;
                        height: 20px;
                        width: 10px;
                        color: #FFFFFF;
                        &:after{
                            content: '/';
                            position: relative;
                            left: 4px;
                        }
                    }
                }
                &__pager--total--slideNpop{
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                    height: 20px;
                    width: 10px;
                    display: block;
                    color: #FFFFFF;
                }
                &__arrow-pagination{
                    display: none;
                }

                /* -- UI -- */
                &__playpause{
                    display: none;
                }
                &__dots{
                    display: none;
                }

                /* -- Texte -- */
                &__text{
                    position: static;
                    transform: none;
                }
                &__title{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    padding: 20px 40px;
                    transform: none;
                }
                &__description{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: calc(100% - 40px);
                    padding: 20px 40px;
                    transform: none;
                }
                &__link{
                    display: none !important;
                }

                /* -- Close -- */     

                &__close{
                    content: '';
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    flex-shrink: 0;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url( svg-bg-uri($svg-close, #FFFFFF) );
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    z-index: 2;
                }   
            }         
        }
    }
}