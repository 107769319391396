.cookies {
	z-index: 100;
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 50px 0 35px 0;
	background: rgba($color: $core-color, $alpha: .9);
	animation: cookieIn 1s;
	//border:2px solid $second-color;


	@keyframes cookieIn {
		from {
			transform: translateY(100%);
			opacity: 0;
		}
	}



	&__container {
		display: flex;
		margin-left: 40px;
		margin-right: 40px;
		flex-wrap: wrap;

		@include RWD(mobile) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__part {
		display: flex;
		color: #fff;

		&--left {
			align-items: flex-start;
			margin-right: 50px;
			font-family: $fancy-font;
			font-weight: bold;
			color: $core-color-font;

			@include RWD(mobile) {
				flex-direction: column;
				align-items: center;
				margin: unset;
			}
		}

		&--right {
			flex-direction: column;
			font-weight: 300;
			color: $core-color-font;

			> div {
				@include RWD(mobile) {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			}

			button {
				margin-top: 15px;
				margin-right: 40px;

				@include RWD(mobile) {
					margin-right: unset;
				}
			}
		}
	}

	&__icon {
		content: '';
		display: inline-block;
		height: 40px;
		width: 40px;
		margin-right: 20px;
		background-color: $page-bg-color;
		background-image: url(svg-bg-uri($svg-cookie, $core-color-font));
		background-position: center;
		background-size: 50%;
		background-repeat: no-repeat;
		transform: translateY(-5px);
		transition: .2s;
		flex-shrink: 0;
		border-radius: 50%;

		@include RWD(mobile) {
			margin-bottom: 25px;
			margin-right: unset;
		}
	}
}

.btn-cookies {
	border: solid 2px transparent;
	background: $page-bg-color;
	// &:hover{
	// 	background:$core-color;
	// 	border:solid 2px transparent;
	// }
}