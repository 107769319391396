.table-content {
	clear: both;
	width: 100%;
	border-collapse: separate;

	caption {
		visibility: hidden;
	}

	td {
		padding: 15px;
		text-align: center;
		font-size: 1.5rem;
		color: $text-color;

		& > *:last-child {
			margin-bottom: 0;
		}
	}

	th {
		padding: 15px;
		text-align: center !important;
		padding: 20px;
		background-color: $core-color;
		text-transform: uppercase;
		font-size: 1.6rem;
		font-weight: 700;
		color: $core-color-font;

		& > *:last-child {
			margin-bottom: 0;
		}
	}

	tbody > tr {
		&:nth-child(even) {
			background-color: #F4F4F4;
		}
	}

	&--responsive {
		@extend .table-content;

		@media(max-width: 39.9375em) {
			&.tablesaw-stack td {
				display: flex;
				flex-wrap: wrap;
			}

			&.tablesaw-stack td .tablesaw-cell-label,
			&.tablesaw-stack th .tablesaw-cell-label {
				hyphens: auto;
				word-wrap: break-word;
			}

			b {
				font-weight: 600;
				color: #212121;
			}

			td,
			th {
				text-align: left;
			}

			tr {
				display: block;
				border-color: $core-color;
				border-width: 1px;
			}
		}

		@include RWD(mobile) {
			transform: translateX(-20px);
		}
	}

	clear: both;
	margin-bottom: $margin-item-rte;
}

.rte .table-overflow {
	overflow: auto;
}