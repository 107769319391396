.a-btn {

	&-main {
		padding: 2px 30px;
		border: 2px solid $core-color;
		text-decoration: none !important;
		position: relative;
		cursor: pointer;
		transition: all 0.3s $t-bam;

		display: inline-flex;
		align-items: center;
		justify-content: center;

		.btn-text {
			position: relative;
			font-family: $fancy-font;
			font-weight: 400;
			font-size: 1.7rem;
		}

		.btn-arrow {
			flex-shrink: 0;
			position: relative; // Pour passer au dessus du before
		}

		.flexbox {
			width: 100%;
			z-index: 1;
			display: flex;
			align-items: center;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}

		&:after {
			display: none !important; // Enlève l'external link
		}

		&:hover,
		&:focus {}

		&--core {
			background-color: none;

			// border :2px solid $fancy-font;
			.btn-text {
				color: $footer-color-font;
				font-weight: 400;
			}

			&:hover,
			&:focus {
				background-color: $core-color;
			}
		}

		&--second {
			//background-color: $second-color;
			background-color: transparent;

			.btn-text {
				color: $core-color-font;
			}

			&:hover,
			&:focus {
				color: $core-color-font;
				background-color: $core-color;
			}
		}

	}

	&-link {
		transition: all 0.3s $t-bam;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: $core-color-font;
		height: auto;
		padding: 10px;
		padding-right: 25px;
		position: relative;
		text-decoration: none;
		font-weight: bold;

		.btn-text {
			position: relative;
			font-weight: 400;
			font-size: 2rem;
			line-height: 2.5em;
			font-family: $fancy-font;
		}

		.btn-arrow {
			flex-shrink: 0;
			margin-left: 15px;
			position: relative; // Pour passer au dessus du before
			content: '';
			display: block;
			width: 13px;
			height: 13px;
			transition: all .5s ease;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		.flexbox {
			width: 100%;
			z-index: 1;
			display: flex;
			align-items: center;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}

		&.opened {
			.btn-arrow {
				transform: rotate(90deg);
			}
		}

		&--core {
			.btn-arrow {
				background-image: url(svg-bg-uri($svg-arrow1, $core-color-font ));
			}

			&:hover {
				color: $second-color;

				.btn-arrow {
					background-image: url(svg-bg-uri($svg-arrow1, $core-color));
				}
			}
		}
	}

	&-pagination {
		border: none;
		padding: 0px;

		&:hover {
			background-color: transparent;
			text-decoration: underline;
		}

		.btn-arrow {
			content: '';
			display: inline-block;
			width: 15px;
			height: 15px;
			flex-shrink: 0;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		@include RWD(mobile) {
			.btn-text {
				font-size: 0;
				display: flex;
			}
		}

		&--prev {
			color: $text-color;

			.btn-arrow {
				background-image: url(svg-bg-uri($svg-arrow1, $core-color-font));
				margin-right: 10px;
				transform: rotate(-180deg);
				order: 1;
				transition: all 0.3s ease-in-out;
			}

			&:hover,
			&:focus {

				.btn-arrow {
					transform: rotate(-180deg) translateX(10px);
				}
			}

			.btn-text {
				order: 2;

			}

			@include RWD(mobile) {
				.btn-text:before {
					content: 'Prev';
					font-size: 1.6rem;
				}
			}
		}

		&--next {
			position: relative;
			color: $core-color-font !important;

			.btn-arrow {
				background-image: url(svg-bg-uri($svg-arrow1, $core-color-font));
				margin-left: 10px;
				transition: all 0.3s ease-in-out;
			}

			&:hover,
			&:focus {
				.btn-arrow {
					transform: translateX(10px);
				}
			}

			@include RWD(mobile) {
				.btn-text:before {
					content: 'Suiv';
					font-size: 1.6rem;
				}
			}
		}
	}
}

.formNextStep {
	transform: translateY(-3px);
}

// .a-btn-pagination--next,
// .a-btn-pagination--prev {
// 	&:focus {
// 		background-color: $core-color;
// 	}
// }