.customSelectContain{
    height:  #{$form-el-height}px;
    
    .customSelect {
        display: flex;
        align-items: center;
        @extend .a-input-text;
        height: 100%;
        
        &.arrowed {
            &:after {
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                content: '';
                display: block;
                height: 20px;
                width: 20px;
                background-size: 15px;
                background-position: center;
                background-repeat: no-repeat;
                transform:  translateY(-50%) rotate(90deg);
                background-image: url( svg-bg-uri($svg-arrow1, #7e7e7e) );
            }
        }
        option{
            padding: 10px;
        }
    }
} 
.toValidate{
    .valid + .customSelect{
        border-color: $success-color !important;
    }
    .error + .customSelect{
        border-color: $error-color !important;
    }
}