.not-front{
    main{
        @extend %text-basis;
    }
    h1{
        @extend .h1;
        margin-bottom: 40px;
    }
    .hat{
        margin-bottom: 30px;
    }

    // Publié le d'une actu
    .actu-published{
        text-align: right;
        font-style: italic;
        color: $core-color;
        font-size: 12px;
        font-size: 1.2rem;
        margin-bottom: 20px;
    }
}