.footerliens {
	height: 60px;
	width: 100%;
	background: #151515;
	display: flex;
	align-items: center;

	@include RWD(mobile) {
		height: auto;
	}

	&__liste {
		display: flex;
		width: 100%;
		justify-items: flex-start;
		align-items: center;

		@include RWD(mobile) {
			flex-direction: column;
		}

		&__item {
			padding-right: 55px;

			@include RWD(mobile) {
				padding-right: 0px;
			}
		}

		&__lien {
			text-decoration: none;
			color: $second-color-font;
			font-size: 1.4rem;

			&:hover {
				@include linkHover($second-color-font, 2);
			}
		}
	}

	&__logo {
		height: 100%;
		display: flex;
		flex-direction: row;

		@include RWD(mobile) {
			justify-content: center;
		}

		&__picto {
			flex-shrink: 0;
			content: '';
			display: inline-block;
			width: 70px;
			height: 100%;
			background-size: 65px;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(svg-bg-uri($svg-logo-telmedia-white, $second-color-font));
			z-index: 10;
		}
	}

	&__date {
		align-items: center;
		font-size: 1.1rem;
		transform: translateY(4px);
		flex-direction: row;
	}

	&__lien__logo {
		width: 100%;
		text-decoration: none;
		color: $second-color-font;
		font-size: 1.4rem;
		display: flex;
		align-items: center;

		&:hover {
			@include linkHover($second-color-font, 2);
		}

		@include RWD(mobile) {
			justify-content: center;

		}
	}
}

.container__footerliens {
	display: flex;
	height: 100%;

	@include RWD(mobile) {
		flex-direction: column;
		padding-top: 30px;
		padding-bottom: 30px;
	}
}