.form__item-intervalle__output {
  display: none !important;
}
.a-range {
  margin: auto;
  outline: none;
  padding: 0;
  width: 50%;
  height: 6px;
  background-color: darkgrey;
  background-image: linear-gradient($core-color, $core-color);
  background-size: 50% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  cursor: pointer;
  -webkit-appearance: none;
  position: relative;
  &::-webkit-slider-runnable-track {
    box-shadow: none;
    border: none;
    background: transparent;
    -webkit-appearance: none;
    height: 50px;
  }
  &::-webkit-slider-thumb {
    width: 14px;
    height: 14px;
    border: 0;
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 0 2px 0px rgba(0,0,0,0.4);
    -webkit-appearance: none;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  &-value {
    display: inline-block;
    position: relative;
    width: 100px;
    color: $core-color-font;
    line-height: 20px;
    text-align: center;
    border-radius: 3px;
    background: $core-color;
    padding: 5px 10px;
    margin-left: 8px;
    &:after {
      position: absolute;
      top: 8px;
      left: -7px;
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-right: 7px solid $core-color;
      border-bottom: 7px solid transparent;
      content: '';
    }
  }

}