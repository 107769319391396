.a-figure {
	figure {
		margin-bottom: 20px;
		max-width: 100%;
		position: relative;
		height: 100%;
		vertical-align: middle;
		background-size: cover;
		background-position: center;

		@include RWD(mobile) {
			float: none !important;
			margin-left: auto !important;
			margin-right: auto !important;
		}

		figcaption {
			padding: 30px;
			background-color: rgba(255, 255, 255, 0.8);
			font-size: 16px;
			font-size: 1.6rem;
			font-weight: 400;
			line-height: 1.63em;
			font-family: $fancy-font;
			position: absolute;
			width: 100%;
			bottom: 0;
			color: #000000;
		}

		// Exception au picto external link
		a:after {
			display: none !important;
		}
	}

	// Tailles
	&--xs figure {
		width: $image-xs;
		height: $image-xs;

		@include RWD(mobile) {
			max-width: $image-xs;
			max-height: $image-xs;
			// width: 100%;
			// height: 100%;
			// padding-top: 100%;
		}
	}


	&--sm figure {
		width: $image-sm;
		height: $image-sm;

		@include RWD(mobile) {
			max-width: $image-sm;
			//max-height:$image-sm;
			width: 100%;
			height: 100%;
			padding-top: 100%;
		}
	}

	&--md figure {
		width: $image-md;
		height: $image-md;

		@include RWD(mobile) {
			max-width: $image-md;
			width: 100%;
			height: 100%;
			padding-top: 100%;
		}
	}

	&--original {
		figure {
			width: auto;
			display: block;
			margin: 0 auto;
			margin-bottom: 20px;
			background-image: none !important;

			img {
				max-width: 100%;
			}
		}
	}

	&--full figure {
		margin-left: calc(50% - 50vw);
		margin-right: calc(50% - 50vw);
		max-width: 100vw;
		position: relative;
		height: 660px;

		@include RWD(mobile) {
			width: 100vw;
			transform: translateX(-20px);
		}

		figcaption {
			// @extend .container;
			padding: 30px 0;
			width: 1200px;
			z-index: 10;
			left: 50%;
			transform: translateX(-50%) translateY(2px);
			background: none !important;
			display: flex;

			@include RWD(tablet) {
				width: 100%;
				padding: 30px;
				left: 0%;
				transform: translateX(0) translateY(2px);
			}

			// pour forcer le retour à la ligne à 50% de largeur sur la légende
			@include RWD(desktop) {
				&:after {
					content: "";
					flex-basis: 50%;
					flex-shrink: 0;
					height: 5px;
				}
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: -5000px;
				right: -5000px;
				z-index: -1;
				height: 100%;
				display: block;
				background-color: rgba(255, 255, 255, 0.8);

				@include RWD(mobile) {
					// position: static;
					// left: 0;
					// right: 0;
				}
			}
		}
	}

	// Floats
	&--left figure {
		float: left;
		margin-right: 40px;
	}

	&--right figure {
		float: right;
		margin-left: 40px;
	}

	// Spe drupal
	@at-root {
		.drupal & {
			clear: both;
		}
	}
}