/**
    slideNpop format Grille
*/

.#{$sfull_name}.grid-slider{
    .#{$sfull_name}-slideNpopGrid{
        &__slider{
            @include RWD(desktop){
               @include grid-distribution(4, 30, slider-cms-slideNpopGrid__slider-item);
            }
            @include RWD(tablet_only){
               @include grid-distribution(2, 30, slider-cms-slideNpopGrid__slider-item);
            }
            @include RWD(mobile){
               @include grid-distribution(1, 0, slider-cms-slideNpopGrid__slider-item);
            }
        }
        &__slider-item{
            height: 400px;
        }
        &__slide{
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}