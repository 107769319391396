.survey{
    padding: 20px 40px;
    background-color: #f2f2f2;
    border-radius: 5px;

    @include RWD(mobile){
        padding: 10px 20px;
    }

    &__title{
        @extend .h3;
        margin-bottom: 20px;
        padding-left: 0;
    }
    &__label, .a-label:not(.option){
        color: $second-color;
        font-size: 1.6rem;
        text-transform: none;
        font-weight: 500;
    }

    .form__group{
        &--radio, &--checkbox{
            .form__field{
                flex-flow: row wrap;
            }            
            .form__item{
                width: 50%;
                &:nth-child(n+3){
                    margin-top: 3px;
                }
                @include RWD(mobile){
                    width: 100%;
                    &:nth-child(n+2){
                        margin-top: 3px;
                    }
                }
            }
            .a-radio:not(:checked) .option,
            .a-checkbox:not(:checked) .option{
                &:before{
                    background-color: transparent;
                }
            }
        }
        &--checkbox{
            .option{
                &:before{
                    border: solid 2px $second-color;
                }
            }
        }
    }
    #recaptcha > *{
        margin-left: 0;
    }
    .form__actions{
        justify-content: flex-end;
    }

    &--thanks{
        background-color: $core-color;
        text-align: center;

        .survey__title{
            color: $core-color-font;
        }
    }

    // Resultats
    &--result{
        background-color: $page-bg-color;
        border: solid 3px $second-color;
    }
    &__group{
        margin-bottom: 35px;
    }
    &__question{
        margin-bottom: 10px;
    }
    &__answers{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    &__answers-item{
        width: calc(50% - 10px);
        display: flex;

        &:nth-child(n+3){
            margin-top: 15px;
        }
        &:nth-child(2n){
            margin-left: 20px;
        }

        .flexbox{
            display: flex;
            flex: 1;
        }

        @include RWD(mobile){
            width: 100%;
            margin-left: 0 !important;
            &:nth-child(n+1){
                margin-top: 15px;
            }
        }
    }
    &__answer{
        font-weight: 500;
        color: #302e2f;
        font-size: 1.4rem;
        margin-right: 45px;
        width: 145px;

        @include RWD(tablet){
            margin-right: 20px;
        }
        @include RWD(mobile){
            flex: 1;
        }
    }
    &__range{
        border: solid 3px $second-color;
        border-radius: 5px;
        height: 20px;
        position: relative;
        margin-right: 10px;
        width: 145px;
        @include RWD(mobile){
            flex: 1;
        }
    }
    &__fill{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background-color: $second-color;
    }
    &__amount{
        color: $core-color;
        font-weight: 600;
        font-size: 1.4rem;
        width: 40px;
    }
}