/**
    slideNpop avec un slider différent en slide et en pop
    Le slider pop est le slider full, le slider "slide" est un new slider créé en JS
*/

.#{$sfull_name}.new-slider,
.#{$sfull_name}.grid-slider {

	.#{$sfull_name} {

		/* Surcharge slider ppal (pop)
        ============================= */

		&-main {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 30;
			height: 100%;
			width: 100%;
			display: none;
			background-color: purple;

			&.opened {
				display: block;
			}

			&__slider {
				height: 100%;
			}

			.owl-stage-outer,
			.owl-stage,
			.owl-item {
				height: 100%;
			}

			&__slider-item {
				height: 100%;
				background-size: contain;
			}

		}

		/* Slider d'amorce (slide)
        ============================= */

		&-slideNpop {
			margin-top: 40px;

			/* ITEM
            ============================= */

			&__slider-item {
				height: 500px;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				cursor: pointer;

				&:before {
					background-image: url(svg-bg-uri($svg-morecircled, #FFFFFF));
					background-position: center;
					background-size: 40px 40px;
					background-repeat: no-repeat;
					opacity: 0;
					transition: all, 0.5s;
				}

				&:hover,
				&:focus {
					&:before {
						opacity: 1;
					}
				}

				&--video {
					&:before {
						display: block;
					}

					.owl-video-play-icon {
						display: none;
					}

					.owl-video-wrapper {
						&:before {
							display: none;
						}
					}
				}
			}


			/* ARROWS
            ============================= */

			&__arrow {


				&-icon {}

				&-pagination {
					display: none;
				}

				&--prev {}

				&--next {}

				&--total {}
			}

			/* Pager
            ============================= */

			&__pager {
				&--current {}

				&--prev {}

				&--next {}
			}


			/* DOTS
            ============================= */

			&__dots {
				display: none !important;
			}

			&__dot {


				&.active {}
			}

			/* Playpause
            ============================= */

			&__playpause {
				display: none;

				&.play {}

				&.pause {}
			}
		}
	}

}