#loader{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $core-color;
    z-index: 400;
    display: none;

    .loaded{
        font-size: 2.0rem;
        font-weight: 900;
        color: #FFFFFF;
        opacity: 0.1;
        margin-top: 10px;
        @include RWD(mobile){
            font-size: 1.4rem;
        }
    }
    svg, img{
        animation: fadeToggle 1s infinite alternate;
        max-width: 80%;
        margin: 0 auto;
        margin-bottom: 10px;
    }
    @keyframes fadeToggle{
        0% {
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
    }
}