footer {
	display: block;

	.container__footer-coordonnees {
		height: 535px;
		@include fadeIn();
		flex-direction: row;
		position: relative;
		display: flex;
		margin-top: 80px;
		padding-bottom:170px;

		@include RWD(tablet) {
			flex-direction: column;
			min-height: 730px;
			height: auto;
		}

		@include RWD(mobile) {
			min-height: 1455px;
			height: auto;
			flex-direction: column;
			margin-top: 50px;
			padding-bottom:60px;
		}

		.footer__coordonnees {
			&-blocs--flex {
				display: flex;

				@include RWD(tablet) {
					justify-content: space-between;
				}

				@include RWD(mobile) {
					flex-direction: column;
				}
			}

			&-bloc {
				width: 400px;
				padding-right: 45px;

				@include RWD(tablet) {
					width: 50%;
					padding-right: 70px;
				}

				@include RWD(mobile) {
					width: 100%;
				}

				&-content {
					&-titre {
						@include RWD(mobile) {
							font-size: 3rem;
						}
					}

					&-sstitre {
						font-size: 2rem;
                        padding-bottom: 45px;
                        
                        @include RWD(tablet){
                            padding-bottom:25px;
                        }

						@include RWD(mobile) {
							padding-bottom: 15px;
						}
					}

					&-infos {
                        color: $footer-color-font;
                        @include RWD(tablet){
                            font-size:1.8rem;
                        }
					}
				}

				&-line {
					display: block;
					font-size: 1.8rem;
				}
			}

			&-bloc-right {
				@include RWD(tablet) {
					padding-right: 0px;
					padding-left: 65px;
				}

				@include RWD(mobile) {
					padding-left: 0px;
					margin-bottom: 30px;
				}
			}

			&-bloc-left {
				@include RWD(mobile) {
					padding-right: 0px;
					margin-bottom: 50px;
				}
			}

			&-titre {
                font-size: 4.0rem;
                @include RWD(mobile){
                    font-size:3.0rem;
                    letter-spacing:1px;
                    line-height:30px;
                }
			}
		}

		.footer__programme {
			@include RWD(tablet) {
				display: flex;
				justify-content: space-between;
				margin-top: 50px;
				margin-bottom: 80px;
			}

			@include RWD(mobile) {
				display: flex;
				flex-direction: column;
				height: auto;
				margin-bottom: 50px;
			}

			&-dl {
				height: auto;
				display: flex;
				padding-bottom: 50px;

				@include RWD(tablet) {
					width: 50%;
				}

				@include RWD(mobile) {
					width: 100%;
					flex-direction: column-reverse;
					justify-items: center;
					margin-bottom: 50px;
				}

				&-img {
					height: 230px;
					width: 110px;
					background: red;

					@include RWD(tablet) {
						height: 160px;
						width: 75px;
					}

					@include RWD(mobile) {
						margin: 0 auto;
						height: 230px;
						width: 110px;
						margin-top: 50px;
					}
				}

				&-bloc {
					padding-left: 32px;

					@include RWD(mobile) {
						padding-left: 0px;
						padding-right: 0px;
					}
				}

				&-content {
					@include RWD(mobile) {
						text-align: center;
					}
				}

				&-titre {
					display: block;
					font-size: 4rem;

					@include RWD(mobile) {
						font-size: 3rem;
					}
				}

				&-sstitre {
					font-size: 2rem;
                    padding-bottom: 35px;
                    

					@include RWD(mobile) {
						padding-bottom: 35px;
					}
				}
			}

			&-prive {
				display: flex;

				@include RWD(tablet) {
					width: 50%;
					align-items: flex-start;
					flex-direction: column;
					padding-left: 65px;
				}

				@include RWD(mobile) {
					width: 100%;
					padding-left: 0px;
					text-align: center;
				}

				&-img {
					height: 110px;
					width: 120px;
					background: green;

					@include RWD(mobile) {
						margin: 0 auto;
					}
				}

				&-bloc {
					@include RWD(mobile) {
						margin: 0 auto;
					}
				}

				&-btn {
					margin-top: 30px;
					margin-left: 25px;

					@include RWD(tablet) {
						margin-left: 0px;
					}
				}
			}
		}

		.footer__arrow {
			position: absolute;
			display: flex;
			flex-direction: column;
			right: 0px;
			bottom: -10px;
			transform: translateX(8px);
			transition: all 0.5s ease-in-out;

			&-picto {
				flex-shrink: 0;
				content: '';
				display: inline-block;
				width: 25px;
				height: 25px;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url(svg-bg-uri($svg-arrow1, $core-color-font));
				transform: rotate(-90deg);
                z-index: 10;
                cursor:pointer;

				@include RWD(tablet) {
					bottom: 110px;
				}

			}

			&-line {
				height: 90px;
                border: 1px solid $core-color;
                cursor: pointer;
			}

			@include RWD(tablet) {
				right: 30px;
			}

			@include RWD(mobile) {
				transform: translateX(-5px);
			}

			&:hover {
				.arrow {
					transform: rotate(-90deg) translateX(10px);
					transition: all 0.5s ease-in-out;
				}
			}
		}
	}
	h1 {
		margin-bottom:0px!important;
	}
}