.side-menu-ajax__pages {
    transform: translateX(100%);
    transition: all, 1s;
    z-index: 90;
    background-color: #f5f5f5;
    overflow: auto;
    width: 100%;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 320px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    &.page-opened {
        transform: translateX(0);
        opacity: 1;
    }
    @include RWD(tablet) {
        padding: 20px;
    }
    &-overflow {
        display: block;
        overflow: auto;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 80px;
        height: calc(100vh - 110px);
        @include RWD(tablet) {
            height: calc(100vh - 70px);
        }
        @include RWD(mobile) {
            height: calc(100vh - 40px);
        }
    }
    &-overlay {
        width: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: #f5f5f5;
        z-index: 1000;
        transition: opacity 0.5s 0s, width 0s 0.5s;
    }
    &.page-loading {
        .side-menu-ajax__pages-overlay {
            width: 100%;
            opacity: 1;
            transition: opacity 0.5s 0s, width 0s 0s;
        }
    }
    &-closer {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        &-picto {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            background-size: 20px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(svg-bg-uri($svg-close, #757575));
        }
        &-text {
            color: #757575;
            font-family: $main-font;
            font-weight: 600;
            font-size: 1.3rem;
            margin-left: 20px;
            text-transform: uppercase;
        }
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    section {
        margin-bottom: 40px;
        .section-title {
            margin-bottom: 15px;
        } // Types de widgets
        .box {
            border-radius: 5px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
            width: 100%;
            background-color: #FFFFFF;
            // @extend %atom-user-components;
        }
    }
}