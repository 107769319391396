.post-header-int {
	width: 100%;
	min-height: 720px;
	height: auto;
	margin-top: 150px;
	margin-bottom: 65px;
	position: relative;

	@include RWD(tablet) {
		min-height: 575px;
		margin-bottom: 40px;
	}

	&-logo {
		display: flex;
		flex-direction: row;
		color: $core-color-font;
		padding-top: 55px;

		&-container {
			height: 220px;
			width: 100%;
			position: absolute;
			top: -155px;

			@include RWD(tablet) {
				position: absolute;
				z-index: 10;
			}

			& a {
				text-decoration: none;
				display: block;
				width: 200px;
				height: 100%;
			}
		}

		&-right {
			display: flex;
			flex-direction: column;
		}

		&-left {
			transform: rotate(-90deg) translateX(10px);
			height: 35px;
			align-self: end;
		}

		&-line {
			&--regular {
				font-size: 2.4rem;
			}

			&--bold {
				font-size: 3rem;
				font-weight: 700;
			}

			&--chatou {
				font-size: 1.2rem;
				font-family: $fancy-font;
			}
		}
	}

	&-visu {
		height: 500px;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		display: flex;
		align-items: flex-end;

		@include RWD(tablet) {
			width: 100vw;
			height: 580px;
			transform: translateX(-40px);
		}
	}

	&-card {
		height: 405px;
		background: $page-bg-color;
		width: 800px;
		position: absolute;
		-webkit-box-shadow: 6px 10px 5px 8px rgba(50, 50, 50, 0.09);
		box-shadow: 6px 10px 5px 8px rgba(50, 50, 50, 0.09);
		bottom: 40px;

		@include RWD(tablet) {
			width: 100%;
			background: rgba(255,255,255,0.8);
			height: 335px;
			bottom: 0px;
			-webkit-box-shadow: 10px 10px 5px 8px rgba(0, 0, 0, 0);
			box-shadow: 10px 10px 5px 8px rgba(0, 0, 0, 0);

		}

		&-content {
			z-index: 300;


			@include RWD(tablet) {
				height: 280px;
			}
		}

		&-outer {
			height: 100%;
			background: $page-bg-color;
			width: 100%;
			position: absolute;
			top: 0;
			-webkit-box-shadow: -9px 10px 5px 8px rgba(50, 50, 50, 0.09);
			-moz-box-shadow: -9px 10px 5px 8px rgba(50, 50, 50, 0.09);
			box-shadow: -9px 10px 5px 8px rgba(50, 50, 50, 0.09);

			@include RWD(tablet) {
				-webkit-box-shadow: 8px -8px 5px 8px rgba(0, 0, 0, 0);
				-moz-box-shadow: 8px -8px 5px 8px rgba(0, 0, 0, 0);
				box-shadow: 8px -8px 5px 8px rgba(0, 0, 0, 0);
			}

			&-left {
				transform: translateX(-100%) translateY(0px);
				height: 405px;
				background: $page-bg-color;
			}

			&-right {
				@include RWD(desktop) {
					display: none;
				}

				@include RWD(tablet) {
					display: block;
					transform: translateX(100%);
				}
			}
		}
	}

	&-titre {
		padding-left: 50px;
		padding-right: 45px;
		margin-top: 45px;

		@include RWD(tablet) {
			padding-right: 0px;
		}

		@include RWD(mobile) {
			font-size: 3rem;
			line-height: 40px;
			margin-top: 30px;
		}
	}

	&-btn-deco-container {
		width: 100%;
		padding-left: 50px;
		margin-top: 45px;
	}
}