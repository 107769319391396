.a-fancy-file{
    margin-bottom: 10px;
    &:last-child{
        margin-bottom: 0;
    }
    .flexbox{
        display: flex;
    }
    button{
        flex-shrink: 0;
        @include RWD(mobile){
            width: 50px;
            padding: 0;
        }
        .btn-arrow{
            content: '';
            height: 20px;
            width: 20px;
            display: inline-block;
            margin-left: 15px;
            background-image: url( svg-bg-uri($svg-morecircled, $core-color-font) );
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            flex-shrink: 0;
        }
    }
    .fancyFileTrigger{
        margin-right: 20px;
        @include RWD(mobile){
            .btn-text{
                display: none;
            }
            .btn-arrow{
                margin: 0;
            }
        }
    }
    input[type="file"]{
        position: absolute;
        height: 1px;
        width: 1px;
        overflow: hidden;
        opacity: 0;
    }
    .selected-file{
        position: relative;
        flex: 1;
        height: auto !important;
        min-width: 0;
        display: flex;
        align-items: center;
        padding-right: 50px !important;
        @extend .a-input-text;

        span{
            overflow: hidden;
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .arrow{
        content: '';
        position: absolute;
        top: 0; right: 0;
        height: 100%;
        width: 50px;
        display: inline-block;
        background-image: url( svg-bg-uri($svg-close, #757575) );
        background-position: center;
        background-size: 20px;
        background-repeat: no-repeat;
    }
    .a-form-infos{
        width: 100%;
        margin-top: 5px;
        margin-bottom: 0;
    }
}