.a-quote{
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    clear: both;

    & > *:last-child{
        margin-bottom: 0;
    }
    &:before{
        content: '';
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
        width: 75px;
        height: 50px;
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-quote1, $core-color) );
    }
    &__content {
        padding: 10px 5px;
        border: solid 1px $core-color;
        border-left: none;
        border-right: none;
    }
    &__text {
        margin-bottom: 40px;
        text-align: center;
        & > *:last-child{
            margin-bottom: 0;
        }
        & > *:last-child{
            margin-bottom: 0;
        }
    }
    &__author {
        font-style: italic;
        text-align: right;
    }

    ul, ol{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 600px;
        max-width: 100%;

        li{
            text-align: left;
        }
        @include RWD(mobile){
            margin-left: 20px;
            margin-right: 20px;
            width: auto;
        }
    }
}