.a-edito-inlines {
	@extend %text-basis;

	strong:not(.unstyled) {
		font-weight: 700;
	}

	em:not(.unstyled) {
		font-style: italic;
	}

	sup:not(.unstyled) {
		vertical-align: super;
		font-size: smaller;
		line-height: normal;
	}

	sub:not(.unstyled) {
		vertical-align: sub;
		font-size: smaller;
		line-height: normal;
	}

	a:not(.unstyled) {
		text-decoration: none;
		transition: color, 0.3s;
		color: inherit;
		color: $core-color;

		&:hover,
		&:focus {
			color: $text-color;
			text-decoration: underline;
		}

		// &:after{
		//     // vertical-align: super;
		//     font-size: smaller;
		//     text-decoration: none;
		//     content: '';
		//     width: 18px;
		//     height: 13px;
		//     margin: auto 20px; 
		//     display: inline-block;
		//     align-items: center;
		//     background-size: cover;
		//     background-position: center;
		//     background-repeat: no-repeat;
		//     background-image: url( svg-bg-uri($svg-arrow1, $core-color) );
		//     flex-shrink: 0;
		//     transition: all, .3s;
		//}
		&:hover,
		&:focus {
			text-decoration: underline;

			&:after {
				background-image: url(svg-bg-uri($svg-arrow1, $text-color));
			}
		}

		&[href^="http"] {
			color: $text-color;

			&:after {
				vertical-align: super;
				font-size: smaller;
				text-decoration: none;
				margin: 0px 10px;
				content: '';
				position: relative;
				top: 5px;
				width: 12px;
				height: 12px;
				display: inline-block;
				background-size: 10px;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url(svg-bg-uri($svg-external, $text-color));
				flex-shrink: 0;
				transition: all, .3s;
			}

			&:hover,
			&:focus {
				color: $core-color;

				&:after {
					background-image: url(svg-bg-uri($svg-external, $core-color));
				}
			}
		}

		&[href^="mailto"] {
			&:after {
				display: none;
			}

			&:hover,
			&:focus {}
		}
	}
}

.a-edito-lists {

	ul:not(.unstyled),
	ol:not(.unstyled) {
		padding-left: 40px;
		list-style: none;
		margin-bottom: $margin-p;

		@include RWD(mobile) {
			padding-left: 20px;
		}

		ul,
		ol {
			margin-top: $margin-p;
		}

		li {
			margin-bottom: $margin-p;
			position: relative;

			&:last-child {
				margin-bottom: 0;
			}

			& > :last-child {
				margin-bottom: 0;
			}
		}

	}

	ul:not(.unstyled) {
		li {
			&:before {
				content: '';
				height: 7px;
				width: 7px;
				background-color: $core-color;
				margin-right: 30px;
				border-radius: 50%;
				display: inline-block;
				position: relative;
				top: -3px;
			}
		}
	}

	ol:not(.unstyled) {
		counter-reset: item;

		li {
			&:before {
				content: counter(item);
				counter-increment: item;
				color: $core-color;
				margin-right: 25px;
				display: inline-block;
				// position: relative;
				line-height: 1.75em;
				font-size: 2rem;
				font-family: $fancy-font;
				font-weight: 400;
			}
		}
	}
}

.a-edito-headless {
	@extend .a-edito-inlines;
	@extend .a-edito-lists;
}

.a-edito {
	@extend .a-edito-headless;

	p:not(.unstyled) {
		margin-bottom: $margin-p;
	}

	h1:not(.unstyled) {
		@extend .h1;
		margin-top: $mt-h1;
		margin-bottom: $mb-h1;
	}

	h2:not(.unstyled) {
		@extend .h2;
		margin-top: $mt-h2;
		margin-bottom: $mb-h2;
	}

	h3:not(.unstyled) {
		@extend .h3;
		margin-top: $mt-h3;
		margin-bottom: $mb-h3;
	}

	h4:not(.unstyled) {
		@extend .h4;
		margin-top: $mt-h4;
		margin-bottom: $mb-h4;
	}

	h5:not(.unstyled) {
		@extend .h5;
		margin-top: $mt-h5;
		margin-bottom: $mb-h5;
	}

	h6:not(.unstyled) {
		@extend .h6;
		margin-top: $mt-h6;
		margin-bottom: $mb-h6;
	}

	/* Fix pour les Paragraph et liens vide de Yosemite */
	p:not(.unstyled),
	a:not(.unstyled) {
		&:empty {
			display: none;
		}
	}
}