$svg-danger: $svg-danger;
$svg-success: $svg-success;
$svg-close: $svg-close;

$error-color: $error-color;
$sucess-color: $success-color;

#notif-manager{
    position: fixed;
    z-index: 100;
    bottom: 20px;
    right: 20px;
    max-width: 400px;
    
    @include RWD(mobile){
        max-width: calc(100% - 40px);
    }


    .notif{
        display: none;
        max-width: 100%;
        background-color: #ffffff;
        padding: 10px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
        border: solid 1px #F5F5F5;
        border-radius: 5px;

        .nm-container{
            display: flex;
            align-items: center;
        }

        .nm-content{
            @extend %text-basis;
            font-size: 1.2rem;
            ul{
                padding: 0;
            }
        }
        .nm-picto{
            content: '';
            display: inline-block;
            width: 30px;
            height: 30px;
            background-size: 25px;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;
            flex-shrink: 0;
            margin-right: 10px;
        }
        .nm-close{
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            background-size: 20px;
            background-position: center;
            background-repeat: no-repeat;
            flex-shrink: 0;
            background-image: url( svg-bg-uri($svg-close, #757575) ); 
            margin-left: 10px;  
            align-self: flex-start;   
            cursor: pointer;      
        }

        & + .notif{
            margin-top: 10px;
        }

        &.error{
            border-color: $error-color;
            .nm-picto{
                background-image: url( svg-bg-uri($svg-danger, $error-color) );
            }
        }
        &.success{
            border-color: $success-color;
            .nm-picto{
                background-image: url( svg-bg-uri($svg-success, $success-color) );
            }
        }
    }
}