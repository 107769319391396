/**
    slideNpop avec le même slider
    Au clic sur un li, ou change juste l'affichage en position fixed
*/

.#{$sfull_name}.same-slider{

    // 1 seul slider passé en fixed
    $slideNpopHeight: 500;

    position: relative;
    height: #{$slideNpopHeight}px;

    .#{$sfull_name}-main{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &__slider-item{
            height: #{$slideNpopHeight}px;
        }
        &__close{
            display: none;
        }
    }
    

    .#{$sfull_name}-main.opened{
        position: fixed;
        top: 0; 
        left: 0;
        background-color: $core-color;
        z-index: 100;

        .#{$sfull_name}-main{
            &__slider{
                position: absolute;
                top: 50%; left: 50%;
                transform: translate(-50%, -50%);
                width: calc(100% - 200px);
            }
            &__slider-item{
                height: calc(100vh - 160px);
            }
            &__close{
                display: block;
            }
        }
    }
}

