/*overflow droite slider
========================== */
.container-module {
	//background:lightpink;
	height: 490px;

	@include RWD(tablet) {
		height: 370px;
	}

	@include RWD(mobile) {
		height: 335px;
	}

}

.slider__container {
	width: 100%;
	display: block;
	margin: 0 auto;
	position: absolute;
	top: 200px;
	height: 100%;

	@include RWD(tablet) {
		top: 140px;
		height: 225px;
	}

	@include RWD(mobile) {
		height: 160px;
		top: 175px;
	}
}

.slider__overflow {
	overflow: hidden;
	margin-right: calc(50% - (100vw / 2));
	width: 1600px;

	@include RWD(tablet) {
		width: calc(100% + 40px);
	}

	@include RWD(mobile) {
		width: 100%;
	}
}



/*adaptation du module
===================*/

.slider-anim {
	@include fadeIn();
}

.container-slider {
	height: 485px;
	;
	position: relative;

	//background:red;
	@include RWD(tablet) {
		height: 370px;
	}
}

.slider__aujourdhui-main {
	position: relative;
}

.slider__habillage-texte {
	padding-top: 110px;
	display: flex;
	align-items: baseline;

	@include RWD(tablet) {
		padding-top: 80px;
	}

	@include RWD(mobile) {
		flex-direction: column;
		padding-top: 50px;
	}

	&--trait {
		@include RWD(desktop) {
			display: none;
		}

		@include RWD(tablet) {
			display: none;
		}

		@include RWD(mobile) {
			display: block;
			height: 2px;
			width: 90px;
			margin-left: 0px;
			background: #a3844f;
		}
	}
}

.slider__titre {
	padding-right: 55px;
	text-transform: uppercase;

	@include RWD(mobile) {
		font-size: 4rem;
	}
}

.slider__date {
	font-size: 20px;
}

.slider__fleches {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 100px;

	@include RWD(mobile) {
		position: static;
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
}

.slider-aujourdhui-main__accroche {
	transform: rotate(-90deg);
	width: auto;
	display: inline-block;
	position: absolute;
	//left: -15px;
	left: 30px;
	top: 165px;
	font-family: $fancy-font;
	color: $core-color;
	transform-origin: bottom left;

	@include RWD(tablet) {
		top: 110px;
		font-size: 1.6rem;
	}

	@include RWD(mobile) {
		top: 70px;
		font-size: 1.2rem;
	}
}

.silder__itemhoraires {
	color: $description-slider;
}



/* Configuration
============================= */

$sfull_has_overlay: true;
$sfull_animation_time: 1000ms;
$sfull_name: 'slider-aujourdhui';

.#{$sfull_name} {

	&-main,
	&-thumbnail,
	&-slideNpop {
		position: relative;

		/* ITEM
        ============================= */
		&__slider-item {
			position: relative;
			height: 290px;

			@include RWD(tablet) {
				height: 230px;
			}

			@include RWD(mobile) {
				height: 160px;
			}
		}

		&__slide {
			height: 100%;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;

			// @include RWD(tablet) {
			// 	//position: absolute;
			// 	top: 0;
			// 	left: 0;
			// 	height: 100%;
			// 	width: 100%;
			// }

			@if ($sfull_has_overlay) {
				&:before {
					display: none;
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
					height: 100%;
					width: 100%;
					background-color: rgba(#000000, 0.5);
				}
			}

			&--video {
				@if($sfull_has_overlay) {
					.owl-video-wrapper {
						position: relative;

						&:before {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							z-index: 2;
							height: 100%;
							width: 100%;
							background-color: rgba(#000000, 0.5);
						}
					}
				}

				&:before {
					display: none;
				}

				.owl-video-play-icon {
					z-index: 3;
					background-image: url(svg-bg-uri($svg-player, #FFFFFF));
				}
			}
		}

		/* ARROWS
        ============================= */

		&__arrow {
			z-index: 20;
			overflow: visible;
			align-self: end;
			justify-self: end;

			//transform: translateY(-50%);
			/*.flexbox {
               //position: relative;
                height: 50px;
                width: 50px;
                overflow: visible;
                display: flex;
                justify-self: end;
                align-items: baseline;
                //background-color: $core-color;
            }*/
			&-icon {
				content: '';
				height: 20px;
				width: 20px;
				display: inline-block;
				background-image: url(svg-bg-uri($svg-arrow1, $core-color));
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				flex-shrink: 0;
				transform: translateY(3px);
			}

			&--prev &-icon {
				transform: rotate(-180deg);
			}

			&-pagination {
				position: absolute;
				top: 0;
				height: 100%;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $second-color;
				color: $second-color-font;
			}

			&--prev {
				left: 0;

				@include RWD(mobile) {
					position: static;
					transform: translateY(3px);
					margin-right: 25px;
				}
			}

			&--prev &-pagination {
				right: 0;
				transform: translateX(100%);
			}

			&--next {
				right: 0;
				position: absolute;

				@include RWD(mobile) {
					position: static;
					margin-left: 25px;
				}
			}

			&--next &-pagination {
				left: 0;
				transform: translateX(-100%);
			}

			&--total {}
		}

		/* Pager
        ============================= */

		&__pager {
			&--current {
				display: none;
			}

			&--total--slideNpop {
				display: none;
			}

			&--prev {
				&:after {
					content: '/';
				}
			}

			&--next {
				&:after {
					content: '/';
				}
			}
		}

		/* DOTS
        ============================= */

		&__dots {
			position: absolute;
			left: 50%;
			bottom: 40px;
			z-index: 2;
			transform: translateX(-50%);
		}

		&__dot {
			height: 20px;
			width: 20px;
			background-color: #FFFFFF;
			font-size: 0; // Fait disparaître les chiffres dans les cercles
			border-radius: 50%;
			border: solid 2px $core-color;

			&.active {
				background-color: $core-color;
			}

			& + & {
				margin-left: 5px;
			}
		}

		/* Playpause
        ============================= */

		&__playpause {
			content: '';
			position: absolute;
			right: 40px;
			bottom: 40px;
			z-index: 2;
			height: 20px;
			width: 20px;
			display: inline-block;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			flex-shrink: 0;

			&.play {
				background-image: url(svg-bg-uri($svg-play, #FFFFFF));
			}

			&.pause {
				background-image: url(svg-bg-uri($svg-pause, #FFFFFF));
			}
		}

		/* Slider Text
        ============================= */

		&__text {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			color: #FFFFFF;
			transform: translate(-50%, -50%);
			transition: all 0.5s;
			width: calc(100% - 100px);
			width: 100%;
			padding-left: 50px;

			@include RWD(tablet) {
				max-width: calc(100% - 40px);
				padding-right: 40px;
			}
		}

		&__title {
			font-size: 18px;
			color: $core-color-font;
			font-family: $main-font;
			text-align: left;
		}

		&__description {
			font-size: 18px;
			color: $text-color;
			font-family: $description-slider;
			text-align: left;
			padding-bottom: 20px;
		}

		&__link {
			color: #FFFFFF;
		}

		/* Animation, loader et différents états
        ============================= */

		/* -- Loader -- */
		&:not(.loaded) {
			overflow: hidden;
			max-height: 400px;
		}

		.loader {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 30;
			height: 100%;
			width: 100%;
			background-color: #FFFFFF;
			background-color: #FFFFFF;
			border: solid 2px $core-color;
		}

		&.loaded .loader {
			display: none !important;
		}

		/* -- Slides -- */

		.owl-item {
			@keyframes customIn {
				0% {
					transform: translateX(100px);
					opacity: 0;
				}

				100% {
					transform: translateX(0);
					opacity: 1;
				}
			}

			@keyframes customOut {
				0% {
					opacity: 1;
				}

				100% {
					opacity: 0;
				}
			}

			&.active {
				//min-width:400px;
				left: 0px;
			}

			&.animated {
				animation-duration: 1s;
			}

			&.customIn {
				animation-name: customIn;
			}

			&.customOut {
				animation-name: customOut;
			}
		}

		/* -- Texte -- */

		// &.animating &__text {
		//     transform: translate(-50%, -50%) translateY(-40px);
		//     opacity: 0;
		// }

		/* Elements disabled
        ============================= */

		&.no-autoplay &__playpause {
			display: none !important;
		}

		&.no-text &__text {
			display: none !important;
		}

		&.no-dot &__dots {
			display: none !important;
		}

		&.no-pager &__arrow-pagination {
			display: none !important;
		}

		&.no-arrow &__arrow {
			display: none !important;
		}

		&.no-clone .owl-item.cloned {
			opacity: 0 !important;
			pointer-events: none !important;
		}

		& .no_result {
			color: $second-color;
			font-family: $main-font;
			padding-top: 30px;
			font-size: 2.4rem;

			@include RWD(tablet) {
				font-size: 2.2rem;
			}
		}

		&.d-t-no-arrow &__arrow {
			display: none !important;

			@include RWD(mobile) {
				display: block !important;
			}
		}

		&.d-t-no-clone .owl-item.cloned {
			opacity: 0 !important;
			pointer-events: none !important;

			@include RWD(mobile) {
				opacity: 1 !important;
				pointer-events: auto !important;
			}
		}

		&.m-no-arrow &__arrow {
			display: none !important;
		}
	}
}

.owl-item.active:nth-child(4) {
	@include RWD(desktop) {
		opacity: 0.5;
	}

	@include RWD(tablet) {
		opacity: 0.5;
	}
}