.a-highlight-content{
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: auto;
    background-color: $core-color;
    padding: 35px 0px;

    .container {
        & > *{
            color: $core-color-font;
            text-align: center;
        }

        a{
            color: $core-color-font;
            opacity: 1;

            &:hover, &:focus{
                color: $core-color-font;
                opacity: 0.7;
            }
        }
        ul, ol{
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 600px;
            max-width: 100%;

            li{
                text-align: left;
            }
            @include RWD(mobile){
                margin-left: 20px;
                margin-right: 20px;
                width: auto;
            }
        }
        ul li:before{
            background-color: $core-color-font;
        }
        ol li:before{
            color: $core-color-font;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }
}