.home__top__header header {
	width: auto;
}

.home__top__header header .header__container-flex {
	display: flex;
	justify-content: flex-end;
	height: 60px;
	color: $second-color;
	font-family: $fancy-font;
	z-index: 50;

	@include RWD(mobile) {
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}

	@include RWD(tablet) {
		padding-left: 0;
		padding-right: 0;
	}
}

.home__top__header header .header__navbar {
	display: flex;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;
	z-index: 50;
	background: white;

	@include RWD(tablet) {
		display: none;
	}
}


.home__top__header header .header__navbar-small {
	display: flex;
	z-index: 50;

	@include RWD(mobile) {
		width: 100%;
	}
}


.home__top__header header .header__menu-small {
	@include RWD(desktop) {
		display: none;
	}

	@include RWD(tablet) {
		display: flex;
		align-items: center;
		position: relative;
		background: $page-bg-color;
		padding-right: 20px;
		padding-left: 65px;
		cursor: pointer;
	}

	@include RWD(mobile) {
		width: 40%;
		background: rgba(255, 255, 255, 0.8);
	}
}

.home__top__header header .header__menu-burgeropen {
	background: $page-bg-color;
	width: 100%;
	position: absolute;
	z-index: 100;
	height: auto;
	display: none;
	padding-bottom: 30px;
}


.home__top__header header .header__navbar-menutitle {
	position: absolute;
	color: $core-color;
	transform: rotate(-90deg);
	left: 5px;
	top: 95px;

}

.home__top__header header .header__navbar-searchtitle {
	position: absolute;
	color: $core-color;
	transform: rotate(-90deg);
	left: -25px;
	top: 115px;
}

.home__top__header header .header__menu-list {
	display: inline-block;
	padding-top: 55px;
	padding-left: 150px;

	@include RWD(mobile) {
		padding-left: 80px;
	}
}

.home__top__header header .header__menu-item {
	padding-top: 25px;
	font-size: 25px;
	transition: all 0.1s ease-in-out;

	&:hover {
		transform: translateX(10px);
		transition: transform 0.1s ease-in-out;
	}

	& a {
		text-decoration: none;
		color: $second-color;
		border-bottom: 3px #c3916e solid;
		height: 100%;
	}
}

.home__top__header header .header__item {
	padding-left: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	@include RWD(mobile) {
		padding-left: 0px;
	}
}




.home__top__header header .header__item a {
	text-decoration: none;
	color: $second-color;
	display: flex;
	align-items: center;
	height: 100%;
	z-index: 20;
}


.home__top__header header .header__recherche {
	background: $core-color;
	width: 190px;
	z-index: 50;
	position: relative;

	&:before {
		content: '';
		background: white;
		height: 0px;
		width: 0px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		background-position: center;
		border-radius: 100%;
		display: block;
		transition: all 0.2s;
	}

	&:hover {
		transition: all 0.2s;

		&:before {
			content: '';
			background: white;
			background-size: 200%;
			height: 100%;
			width: 100%;
			position: absolute;
			display: block;
			border-radius: 0%;
		}
	}

	@include RWD(tablet) {
		margin-left: 0px;
	}

	@include RWD(mobile) {
		width: 30%;
	}
}

.home__top__header header .header__recherche-text {
	z-index: 20;

	@include RWD(mobile) {
		display: none;
	}
}

.home__top__header header .header__menu-searchopen {
	background: $page-bg-color;
	position: absolute;
	width: 100%;
	z-index: 100;
	height: 240px;
	display: none;
}

.home__top__header header .header__navbar-searchopentext {
	display: inline-block;
	position: absolute;
	top: 90px;
	left: 80px;
}

.home__top__header header .header__navbar-searchopentext-underline {
	position: absolute;
	border-color: $core-color;
	top: 110px;
	left: 80px;
	width: 90px;
	height: 2px;
}

.home__top__header header .header__navbar-searchinput {
	border: 0;
	outline: 0;
	border-bottom: #dadada 1px solid;
	left: 80px;
	position: absolute;
	top: 145px;
	width: 400px;

	@include RWD(mobile) {
		width: 60%;
	}
}

.home__top__header header .header__navbar-searchbutton {
	position: absolute;
	text-decoration: none;
	color: #909090;
	font-size: 13.5px;
	left: 410px;
	top: 170px;

	@include RWD(mobile) {
		width: 70%;
		right: 0px;
		left: auto;
		top: 170px;
	}
}




.home__top__header header .header__billet {
	width: 190px;
	background: #cdcdcd;
	z-index: 50;

	&:before {
		content: '';
		background: white;
		height: 0px;
		width: 0px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		background-position: center;
		border-radius: 50%;
		display: block;
		transition: all 0.3s;
	}

	&:hover {
		transition: all 0.3s;

		&:before {
			content: '';
			background: white;
			background-size: 200%;
			height: 100%;
			width: 100%;
			position: absolute;
			display: block;
			border-radius: 0%;
		}
	}

	@include RWD(mobile) {
		width: 30%;
	}
}

.home__top__header header .header__margin {
	z-index: 1;

	@include RWD(tablet) {
		display: none;
	}
}

.home__top__header header .header__margin::after {
	content: "";
	background-color: $page-bg-color;
	height: 60px;
	//width:240px;
	position: absolute;
	background: white;
	top: 0;
	right: 0;

	@include RWD(tablet) {
		display: none;
	}
}

.home__top__header header .recherche {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 20px;
	//transition: all, 0.5s;
	flex-shrink: 0;
	//margin-left: 20px;
	content: '';
	display: inline-block;
	width: 20px;
	height: 20px;
	background-size: 20px;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(svg-bg-uri($svg-glass, $core-color-font));

	@include RWD(mobile) {
		position: static;
		transform: translateY(0%);
	}
}

.home__top__header header .billet {
	position: absolute;
	top: 50%;
	transform: translateY(-50%) scaleX(-1);
	left: 20px;
	//transition: all, 0.5s;
	flex-shrink: 0;
	//margin-left: 20px;
	content: '';
	display: inline-block;
	width: 30px;
	height: 30px;
	background-size: 25px;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(svg-bg-uri($svg-ticket, $core-color-font));
	height: 100%;

	@include RWD(mobile) {
		position: static;
		transform: translateY(0%);
	}
}

.home__top__header header .burger {
	position: absolute;
	//top: 50%;
	//transform: translateY(-50%);
	left: 25px;
	//transition: all, 0.5s;
	flex-shrink: 0;
	//margin-left: 20px;
	content: '';
	display: inline-block;
	width: 17px;
	height: 17px;
	background-size: 17px;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(svg-bg-uri($svg-map-menu, $core-color-font));
}

.home__top__header header .burgerclose {
	position: absolute;
	top: 25px;
	left: 25px;
	flex-shrink: 0;
	content: '';
	display: inline-block;
	width: 17px;
	height: 17px;
	background-size: 17px;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(svg-bg-uri($svg-cross, $core-color-font));
	opacity: 1;
	cursor: pointer;
}

.home__top__header header .visible {
	display: block !important;
}

.home__top__header .header__navbar .header__item {
	height: 100%;

	&:before {
		content: '';
		background: $core-color;
		height: 0px;
		width: 0px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		background-position: center;
		border-radius: 100%;
		display: block;
		transition: all 0.3s;
		margin-left: 15px;
		margin-right: 15px;
	}

	&:hover {
		transition: all 0.3s;

		&:before {
			content: '';
			background: $core-color;
			background-size: 200%;
			height: 100%;
			width: 100%;
			position: absolute;
			display: block;
			border-radius: 0%;
		}
	}
}