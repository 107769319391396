.#{$sfull_name}.slider-slideNpop {
	.#{$sfull_name}-main {
		&.opened {
			.#{$sfull_name}-main {

				/* -- Pager -- */
				&__pager {
					&--current {
						position: absolute;
						right: 40px;
						bottom: 20px;
						height: 20px;
						width: 10px;
						display: block;
						color: #FFFFFF;

						&:after {
							position: relative;
							left: 4px;
							content: '/';
						}
					}
				}

				&__pager--total--slideNpop {
					position: absolute;
					right: 20px;
					bottom: 20px;
					height: 20px;
					width: 10px;
					display: block;
					color: #FFFFFF;
				}

				&__arrow-pagination {
					display: none;
				}

				/* -- UI -- */
				&__playpause {
					display: none;
				}

				&__dots {
					display: none;
				}

				/* -- Texte -- */
				&__text {
					position: static;
					transform: none;
				}

				&__title {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					padding: 20px 40px;
					transform: none;
				}

				&__description {
					position: absolute;
					left: 0;
					bottom: 0;
					width: calc(100% - 40px);
					padding: 20px 40px;
					transform: none;
				}

				&__link {
					display: none !important;
				}

				/* -- Close -- */

				&__close {
					position: absolute;
					top: 20px;
					right: 20px;
					content: '';
					z-index: 2;
					height: 40px;
					width: 40px;
					display: inline-block;
					background-image: url(svg-bg-uri($svg-close, #FFFFFF));
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					flex-shrink: 0;
				}
			}
		}
	}
}