 .footer-int {
 	height: auto;
 	width: 100%;
 	margin-top: 130px;
 	display: flex;
 	flex-direction: row;
 	padding-bottom: 170px;

 	@include RWD(tablet) {
 		flex-direction: column;
 		padding-bottom: 40px;
 	}

 	&-logo {
 		height: 120px;
 		width: 130px;
 		background-image: url("../../images/logo-bordeaux-violet.jpg");
 		background-size: cover;
 		background-repeat: no-repeat;
 		background-position: center;

 		&--desktop {
 			@include RWD(tablet) {
 				display: none;
 			}

 			@include RWD(mobile) {
 				display: none;
 			}
 		}

 		&--tablet {
 			@include RWD(desktop) {
 				display: none;
 			}

 			@include RWD(tablet) {
 				display: block;
 			}

 			@include RWD(mobile) {
 				display: none;
 			}
 		}

 		&--mobile {
 			@include RWD(desktop) {
 				display: none;
 			}

 			@include RWD(tablet) {
 				display: none;
 			}

 			@include RWD(mobile) {
                 display: block;
                 margin:0 auto;
 			}
 		}
 	}

 	&-coordonnees,
 	&-prog {
 		display: flex;
 		flex-direction: row;
 		margin-left: 100px;

 		@include RWD(tablet) {
 			margin-left: 0px;
 			margin-bottom: 50px;
         }
         @include RWD(mobile){
             flex-direction:column-reverse;
         }

 		&-content {
 			display: flex;
 			flex-direction: column;

 			@include RWD(tablet) {
 				margin-right: 65px;
             }
             @include RWD(mobile){
                 margin:0 auto;
                 align-items:center;
             }

 			&-btn {
 				width: 160px;
 			}
 		}

 		&-titre {
 			display: flex;
 			flex-direction: column;

 			&-line {
 				&-1 {
 					line-height: 30px;
                     text-transform: uppercase;
                     @include RWD(mobile){
                         font-size:3rem;
                     }
 				}

 				&-2 {
 					line-height: 30px;
 					font-size: 2rem;
 					margin-top: 5px;
                     margin-bottom: 25px;
                     @include RWD(mobile){
                         margin-bottom:20px;
                     }
 				}
 			}
 		}

 		&-adresse {
 			display: flex;
 			flex-direction: column;
 			color: $footer-color-font;
             font-size: 1.8rem;
             @include RWD(mobile){
                 font-size:1.6rem;
                 line-height:25px;
             }
 		}

 		&-trait {
 			height: 30px;
 			width: 100%;
 			border-bottom: solid 2px $core-color;

 			&-container {
 				height: 100%;
 				width: 60px;
 				margin-right: 25px;

 				@include RWD(tablet) {
 					display: none;
 				}
 			}
 		}
 	}

 	&-prog {
 		&-visu {
 			height: 230px;
 			width: 110px;
 			background-color: red;
 			margin-right: 35px;
 			background-image: url('');

 			@include RWD(tablet) {
 				height: 160px;
 				width: 80px;
             }
             @include RWD(mobile){
                height: 230px;
                width: 110px;
                margin-top: 55px;
                margin: 0 auto;
                margin-top: 55px;
             }
 		}
 	}
 }