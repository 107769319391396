.side-menu-ajax{
    
    // Overlay noir
    &__overlay{
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: 100%;
        bottom: 0px;
        background-color: rgba(#000000,0.8);
        content: '';
        z-index: 10;
        width: 0;
        opacity: 0;
        transition: opacity 0.5s; 
    }
    
    // Panel
    &__main-panel{
        min-width: 280px;
        width : 30%;
        position: fixed;
        z-index: 99;
        top: 0;
        transform: translateX(-100%);
        transition: all, 0.5s;
        padding: 20px 40px;
        padding-bottom: 0;
        transform: translateX(100%);
        background-color: $second-color;
        left: auto;
        right: 0;
        bottom: 0;
        padding-top: 10px;
        opacity: 0;
    }

    &__opener{
        &:focus{
            outline: none;
        }
    }
    // Bouton Close
    &__closer{ 
        padding: 15px 15px;
        border-radius: 5px;
        background-color: $second-color;
        transform: translateX(0);
        display: flex;
        align-items: center;

        &-picto{
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            // flex-shrink: 0;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url( svg-bg-uri($svg-cross, $second-color-font) );               
        }
        &-text{
            display: inline-block;
            font-size: 1.4rem;
            font-weight: 500;
            text-transform: uppercase;
            font-family: $main-font;
            color: $core-color-font;
            margin-left: 15px;
            transition: all, 0.5s;
        }
    }

    // Container pour gérer l'overflow
    &__overflow{
        width : 280px; 
        overflow: auto;
        overflow-x: hidden;
        margin-right: -40px;
        padding-right: 40px;
        margin-left: -40px;
        padding-left: 40px;
        height: calc(100% - 80px);

        &::-webkit-scrollbar-track{
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar{
            width: 3px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb{
            background-color: #ffffff;
        }

    }

    // Vignette user
    &__user{
        display: block;
        text-align: center;
        padding-bottom: 20px;
        // margin-bottom: 25px;
        &-picture{  
                text-align: center;
                margin: 0 auto;
                margin-bottom: 10px;
                height: 80px;
                width: 80px;
                border-radius: 50%;
                border: solid 2px #FFFFFF;
                background-size: cover;
                background-position: center;
        }
        &-name{
            text-align: center;
            font-weight: 400;
            font-size: 1.3rem;
            font-family: $main-font;
            color:  $core-color-font;
        }
        &-job{
            text-align: center;
            font-weight: 400;
            font-size: 1.3rem;
            font-family: $main-font;
            color: #FFFFFF;
        }
        &-hello {
            font-weight : 400;
            font-size: 1.7rem;
            color : $core-color-font;
            text-transform: uppercase;
        }
    }
    
    //nombre de msg 
    &__overview-msg {
        padding : 5px 10px; 
        font-weight: 600;
        font-size: 1.4rem;
        line-height : 2.07em;
        font-family: $main-font;
        color:  $core-color-font;
        border-bottom: solid 1px #60d7e6;
        border-top: solid 1px #60d7e6;
        margin-bottom : 20px;
        display : flex;
        align-items: baseline;

        span {
            border-radius : 50%;
            width : 25px;
            height : 25px;
            align-items: center;
            display : flex;
            justify-content: center;
            margin-left : 5px;
            background-color : #f16e52;
        }
    }

    // Menu user
    &__menu{
        &-item{ 
            padding: 15px 0 15px 0px;
            border: none;
            font-size: 1.4rem;
            font-weight: 600;
            text-align: right;
            position: relative;
            line-height : 2.07em;
            color: #FFFFFF;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: $main-font;
            transition: all, 0.5s;
            width: 100%;

            &:after{
                background-color: #60d7e6;
                right: auto;
                left: 0;
                transform: rotate(180deg) translateY(50%);
            } 
            &:before{
                content: '';
                background-color: #FFFFFF;
                position: absolute;
                top: 0; left: -50px; right: -50px;
                bottom: 0;
                z-index: -1;
                opacity: 0;
                transition: all, 0.5s;
            }
            &:hover, &:focus, &.active{ 
                outline: none;
                color: $second-color;
                &:after{
                    // background-color: #c9d2d5;
                }
                &:before{
                    opacity: 1;
                }

                .side-menu-ajax__menu-arrow {
                    background-color : #c9d3d5;
                    background-image: url( svg-bg-uri($svg-map-chevron, $core-color-font) );
                }
            }

            &:first-child{
                font-weight: 500;
                &:after{
                    display: block;
                }
            }
            
            &[href="#"]{
                cursor: not-allowed;
            }

            &:hover, &:focus{
                color: $second-color !important;
            }
        }
        &-arrow {
            content: '';
            display: inline-block;
            width: 30px;
            height: 30px;
            flex-shrink: 0;
            transform: rotate(180deg);
            background-size: 50%;
            // background-color : #c9d3d5;
            background-color : $core-color-font;
            background-position: center;
            background-repeat: no-repeat;
            // background-image: url( svg-bg-uri($svg-map-chevron, $core-color-font) );
            background-image: url( svg-bg-uri($svg-map-chevron, $second-color) );
        }
    }

    // Etat ouvert
    &.opened{
        transition-delay: 0s;

        .side-menu-ajax__overlay{
            width: 100%;
            opacity: 1;
            z-index: 90;
        }

        &.main-panel-open{
            .side-menu-ajax__main-panel{
                z-index: 99;
                transform: translateX(0);
                opacity: 1;
            }
            .side-menu-ajax__closer{                
                transform: translateX(-85px);
                @include RWD(mobile){
                    transform: translateX(-50%);                        
                }
            }
        }

    }
}