.a-edito{


    /* Listes */
    ul:not(.unstyled){ 
        overflow: hidden;
    }
    ol:not(.unstyled){
        overflow: hidden;
    }  

    // Options CLEARFIX
    p, h2, h3, h4, h5, ul, ol{
        &.clearfix{
            clear: both;
        }
    }

    .rte-item{
        clear: both;
        margin-bottom: $margin-item-rte;
        & > *:last-child{
            margin-bottom: 0;
        }
    }
}