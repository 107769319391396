.pagination {
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: flex-start;
	align-items: center;


	.filler {
		flex: 1;
	}

	&-item {
		margin-left: 25px;
		position: relative;
	}


	&:first-child {
		margin-left: 0;
	}



	// Style des nombres
	&__number {
		text-decoration: none;
		position: relative;

		.btn-text {
			position: relative;
			font-family: $main-font;
			font-size: 1.5rem;
			color: $text-color;
			font-weight: 600;
			padding: 3px;
			height: 30px;
			width: 30px;

			&:hover {
				&:before {
					content: '';
					position: absolute;
					background-color: $core-color;
					border-radius: 50%;
					width: 30px;
					height: 30px;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: -1;
				}
			}
		}

		&:hover,
		&:focus {
			.btn-text {
				color: $core-color-font;
			}

			// background-color: $core-color;
			// border-radius:100%;
		}
	}

	// Style actif
	.is-active {
		pointer-events: none;

		&:before {
			content: '';
			position: absolute;
			background-color: $core-color;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: -1;
		}

		.btn-text {
			color: $core-color-font;
		}
	}
}

// Style disabled
.disabled {
	pointer-events: none;
	opacity: 0;
}

@include RWD(tablet) {

	.pagin-first,
	.pagin-last {
		display: none !important;
	}
}

// @include RWD(mobile) {
// 	.pagination__number {
// 		display: none;
// 	}
// }


.pagination-item .a-btn-pagination .flexbox .btn-text {


	&:before {
		content: '';
		position: absolute;
		width: 0%;
		height: 2px;
		background-color: #c2c2c2;
		bottom: 0px;
		transition: width 0.3s;
	}
}

.pagination-item .a-btn-pagination:hover .btn-text {
	&:before {
		width: 100%;
		transition: width 0.3s;
	}
}

.pagin-debut .a-btn-pagination--prev .flexbox .btn-arrow {
	display: none;
}

.pagin-fin .a-btn-pagination--next .flexbox .btn-arrow {
	display: none;
}

.pagin-next{
	@include RWD(mobile) {
		transform: translateY(-4px);
	}

	&:hover {
		@include RWD(mobile) {
			transform: translateY(-4px);
		}
	}
}

.pagin-prev{
	@include RWD(mobile) {
		transform: translateY(3px);
	}

	&:hover {
		@include RWD(mobile) {
			transform: translateY(3px);
		}
	}
}