.m-stepform{
    clear: both;
    margin-bottom: $margin-item-rte;

    // Progress Bar étapes
    .a-progressbar{
        position: relative;

        // Gestion des ma-width en fonction du nombre d'items
        @for $i from 1 through 10{
            &[data-page="#{$i}"]{
                .webform-progressbar-page{
                    max-width: #{100% / $i};
                }
            }
        }

        .webform-progressbar-outer{
            display: flex;
            justify-content: space-between;
            overflow: hidden;
        }
        
        // Barre de progression derriere les chiffres
        .webform-progressbar-wrapper{
            background-color: #edf2e7;
            position: absolute;
            top: 35px;
            left: 30px;
            width: calc(100% - 60px);
            transform: translateY(-50%);
            height: 2px;
            z-index: -1;

            @include RWD(mobile){
                // display: none;
                top: 15px;
            }

            .webform-progressbar-inner{
                height: 100%;
                background-color: #F4F4F4;
            }
        }

        // %age de progression
        .webform-progressbar-number{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 30px; 

            &:after{
                display: block;
                content: ''; 
                margin-top: 10px;
                height: 1px;
                background-color: #d8d8d8;
                width: 80%;
            }
            .flexbox{
                padding: 5px;
                background-color: #f7f7f7;
                color: $text-color;
            }
        }

        .webform-progressbar-page{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: none;
            position: relative;

            &:hover, &:focus{
                text-decoration: none;
            }

            &:first-child{
                background: linear-gradient(to right, $page-bg-color 0%, $page-bg-color 50%, transparent 50%, transparent 100%);
            }
            &:last-child{
                background: linear-gradient(to left, $page-bg-color 0%, $page-bg-color 50%, transparent 50%, transparent 100%);
            }
            

            &-number{
                height: 70px;
                width: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5000px;   
                font-weight: 600;
                font-size: 16px;
                font-size: 1.6rem;    

                @include RWD(mobile){
                    height: 30px;
                    width: 30px;
                }     
            }
            &-label{
                text-transform: uppercase;
                font-size: 16px;
                font-size: 1.6rem;
                font-weight: 600;
                margin-top: 15px;
                padding: 0 5px;
                max-width: 100%;

                @include RWD(mobile){
                    display: none;
                }
            }

            // DEFAULT

            &-number{
                background-color: #f7f7f7;
                color: $text-color;     
            }
            &-label{
                color: $text-color;
            }
    

            // CURRENT
            &.current{
                .webform-progressbar-page-number{
                    background-color: $second-color;
                    color: $second-color-font;
                }
            }

            // Validé
            &.completed{
                .webform-progressbar-page-number{
                    background-color: $core-color;
                    color: $core-color-font;
                }
            }
        }
    }

    // Prévisualisation
    &.preview{
        label{
            padding: 0 !important;
        }
        h2.webform-page{
            @extend .h2;
            margin-bottom: 30px;
        }
        .webform-grid{
            .form-field{
                overflow: auto; 
            }
        }
        table.webform-grid{
            td.webform-grid-option{
                padding: 0;
                strong{
                    background-color: $core-color;
                    height: 45px;
                    width: 27px;
                    font-size: 0; 
                    display: inline-block;
                    vertical-align: top;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 17px auto;
                    background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FscXVlXzEiIGRhdGEtbmFtZT0iQ2FscXVlIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDE1NCAxMjAuNjciPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO3N0cm9rZTojRkZGRkZGO3N0cm9rZS1taXRlcmxpbWl0OjEwO3N0cm9rZS13aWR0aDoxM3B4O308L3N0eWxlPjwvZGVmcz48dGl0bGU+UGxhbiBkZSB0cmF2YWlsIDE8L3RpdGxlPjxwb2x5bGluZSBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTQ5LjUgNS4zMyA0NSAxMTAuNzMgNSA3Mi4yMyIvPjwvc3ZnPg==');
                }
            }
        }
    }
    
    // Confirmation d'envoi
    .webform-confirmation{
        label{
            padding: 0 !important;
        }
        h2.webform-page{
            @extend .h2-raw;
            margin-bottom: 30px;
        }
        .webform-grid{
            .form-field{
                overflow: auto; 
            }
        }
        table.webform-grid{
            td.webform-grid-option{
                padding: 0;
                strong{
                    background-color: $core-color;
                    height: 45px;
                    width: 27px;
                    font-size: 0; 
                    display: inline-block;
                    vertical-align: top;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 17px auto;
                    background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FscXVlXzEiIGRhdGEtbmFtZT0iQ2FscXVlIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDE1NCAxMjAuNjciPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO3N0cm9rZTojRkZGRkZGO3N0cm9rZS1taXRlcmxpbWl0OjEwO3N0cm9rZS13aWR0aDoxM3B4O308L3N0eWxlPjwvZGVmcz48dGl0bGU+UGxhbiBkZSB0cmF2YWlsIDE8L3RpdGxlPjxwb2x5bGluZSBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTQ5LjUgNS4zMyA0NSAxMTAuNzMgNSA3Mi4yMyIvPjwvc3ZnPg==');
                }
            }
        }
    }
    // Messages d'erreurs BO
    .messages.error, .messages.warning{
        border: none;
        padding: 20px 20px 20px 85px;
        color: #9a9a9a;
        background: none; 
        background-color: #f1f1f1;
        position: relative;

        &:before{
            position: absolute;
            left: 20px;
            top: 20px;
            content: '';
            height: 45px;
            width: 45px;
            display: block;
            background-position: center;
            background-size: auto 55px;
            background-repeat: no-repeat;
            background-image: url( svg-bg-uri($svg-exclamation, $core-color) );
            border: solid 2px $core-color;
            border-radius: 50%;
        }

        h2{
            display: block;
            font-size: 20px;
            font-size: 2.0rem;
            color: #7e8c8d;
            margin-bottom: 20px;
            font-weight: 500;
        }
        @extend .a-edito-headless;
        & > *:last-child{        
            margin-bottom: 20px;
        }
    }
}