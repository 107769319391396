/**
    ---------- SKIN CMS
*/

.grid-morph--list{
    .gridy__item{
        position: relative;
        & + .gridy__item{
            margin-top: 20px;
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #808080;
            }
        }
    }
}
.a-teaser-morph--list{
    display: flex;
    text-decoration: none; 
    color: inherit;
    transition: all, 0.5s;
    padding-top: 20px;
    position: relative;
    @include RWD(mobile){
        display: block;
    }

    &__visu{
        position: relative; 
    }

    &__background{
        background-size: cover;
        background-position: center;
        height: 250px;
        width: 250px;
        transition: all, 0.5s;

        &:before{
            content: '';
            position: absolute;
            top: 0; left: 0;
            height: 100%;
            width: 100%;
            z-index: 2;
            background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
            opacity: 0;
            transition: all, 0.5s;
        }
    }

    &__text{
        padding: 20px;
        padding-bottom: 0;

        @include RWD(mobile){
            margin-bottom: 20px;
        }
    }

    &__title{
        @extend .h3-raw;
        padding-left: 0;
        margin-bottom: 0;
        color: $core-color;

        & + .a-teaser-morph__description{
            margin-top: 15px;
        }
    }
    &__description{
        @extend %text-basis;
        & > *:last-child{
            margin-bottom: 0;
        }
    }
    &__infos{
        display: flex;
        justify-content: space-between;
        font-family: $main-font;
        font-style: italic;
        font-size: 1.0rem;
        color: #3b3b3b;
        margin-top: 15px;

        & > * {
            margin-bottom: 0;
        }
    }

    &__date{
        display: flex;
        height: 75px;
        z-index: 10;


        .date-display-range{
            display: flex;
        }
        .date{
            width: 80px;
            background-color: $core-color;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            align-content: center;
            text-align: center;
            min-height: 70px;

            font-size: 1.4rem;
            color: $core-color-font;
            font-weight: 500;
            line-height: 1;
            padding: 7px;
            
            
            .date-label{
                width: 100%;
                line-height: 1;
                margin-bottom: 5px;
            }
            .day{
                font-weight: 600;
                font-size: 24px;
                font-size: 2.4rem;
                width: 100%;
                line-height: 1;
                margin-bottom: 5px;
            }
            .month{
                text-transform: uppercase;
                line-height: 1;
            }
            .year{
                margin-left: 5px;
                line-height: 1;
            }
        }
    }
    
    // Catégorie
    &__categories{
        max-width: calc(100% - 20px);
        background-color: #FFFFFF;
        color: $second-color;
        text-transform: uppercase;
        font-size: 16px;
        font-size: 1.6rem;
        padding: 6px 10px;
        display: inline-flex;
        align-items: center;
    }
}
a.a-teaser-morph--list{
    &:hover, &:focus{
        background-color: #FFFFFF;

        .a-teaser-morph--list__visu{
            &:before{
                opacity: 1;
            }
        }
    }
}