/**
    ---------- SKIN CMS
*/

.grid-morph--cms {
  .gridy__item {
    @include RWD(mobile) {
      margin-bottom: 20px;
    }
  }
}
.a-teaser-morph--cms {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 30px 20px;
  background-color: #f7f7f7;
  border: solid 2px #f7f7f7;
  color: inherit;
  text-decoration: none;
  transition: all, 0.5s;
  @include RWD(mobile) {
    padding: 0;
  }

  &__visu {
    overflow: hidden;
    position: relative;
    height: 250px;
    @include RWD(mobile) {
      height: auto;
    }

    &:before {
      content: '';
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
      opacity: 0;
      transition: all, 0.5s;
    }

    & > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__background {
    height: 100%;
    background-position: center;
    background-size: cover;
    transition: all, 0.5s;

    @include RWD(mobile) {
      height: auto;
      padding-bottom: 80%;
    }
  }

  &__text {
    padding: 20px;
    padding-bottom: 0;

    @include RWD(mobile) {
      margin-bottom: 20px;
    }
  }

  &__title {
    padding-left: 0;
    margin-bottom: 0;
    color: $core-color;
    @extend .h3-raw;
  }
  &__title + &__description {
    margin-top: 15px;
  }
  &__description {
    @extend %text-basis;
    & > *:last-child {
      margin-bottom: 0;
    }
  }
  &__infos {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    font-family: $main-font;
    font-size: 1rem;
    font-style: italic;
    color: #3b3b3b;

    & > * {
      margin-bottom: 0;
    }
  }

  // Date, peut être dans le visu ou le text
  &__date {
    z-index: 10;
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;

    .date-display-range {
      display: flex;
      width: 100%;
    }

    .date-display-start {
      @include RWD(desktop) {
        display: flex;
        align-items: center;
      }
    }

    .date {
      width: 100%;
      min-height: 40px;
      padding: 7px;
      background-color: $core-color;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.2em;
      text-align: center;
      color: $core-color-font;

      @include RWD(tablet) {
        min-height: 70px;
      }

      &__flexbox {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        @include RWD(desktop) {
          width: 100%;
          margin: 0;
        }
      }

      .date-label {
        width: 100%;
        margin-bottom: 5px;
        line-height: 1.2em;
      }

      .day {
        width: 12%;
        font-family: $fancy-font;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.2em;

        @include RWD(desktop) {
          width: 20%;
        }
      }
      .month {
        margin-bottom: 5px;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2em;
      }

      .year {
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 1.2em;
      }

      .hour {
        font-family: $main-font;
        font-size: 1.4rem;

        &__flexbox {
          display: flex;
          justify-content: center;

          @include RWD(desktop) {
            margin-right: 20px;
          }
        }
      }
    }
  }

  // Catégorie
  &__categories {
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 10px;
    max-width: calc(100% - 20px);
    padding: 6px 10px;
    background-color: #ffffff;
    color: $second-color;
  }
}
a.a-teaser-morph--cms {
  &:hover,
  &:focus {
    background-color: #ffffff;

    .a-teaser-morph--cms__visu {
      &:before {
        opacity: 1;
      }
    }
  }
}
