//variables
$cb-size          : 50px;
$cb-color : $core-color;

//cube only
.loader-cube-flipping{
    position: relative;
    min-height: 200px;
    min-width: 200px;
}
.cube-folding{
  width: $cb-size;
  height: $cb-size;
  display: inline-block;
  transform: rotate(45deg);
  font-size: 0;
  span{
    position: relative;
    width: $cb-size/2;
    height: $cb-size/2;
    transform: scale(1.1);
    display: inline-block;
    &::before{
      content: '';
      background-color: $cb-color;
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: $cb-size/2;
      height: $cb-size/2;
      transform-origin: 100% 100%;
      animation: folding 2.5s infinite linear both;
    }
  }
  .leaf2{
    transform: rotateZ(90deg) scale(1.1);
    &::before{
      animation-delay: .3s;
      background-color: darken($cb-color, 5%);
    }
  }
  .leaf3{
    transform: rotateZ(270deg) scale(1.1);
    &::before{
      animation-delay: .9s;
      background-color: darken($cb-color, 5%);
    }
  }
  .leaf4{
    transform: rotateZ(180deg) scale(1.1);
    &::before{
      animation-delay: .6s;
      background-color: darken($cb-color, 10%);
    }
  }
}

//animation
@keyframes folding{
  0%, 10%{
    opacity: 0;
    transform: perspective(140px) rotateX(-180deg);
  }
  25%, 75%{
    opacity: 1;
    transform: perspective(140px) rotateX(0deg);
  }
  90%, 100%{
    opacity: 0;
    transform: perspective(140px) rotateX(180deg);
  }
}

//with loading text and shadow
.cube-wrapper{  
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -$cb-size;
  margin-left: -$cb-size;
  width: $cb-size*2;
  height: $cb-size*2;
  text-align: center;
  //shadow
  // &:after{
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   bottom: -20px;
  //   margin: auto;
  //   width: $cb-size*1.8;
  //   height: 6px;
  //   background-color: rgba(black,.1);
  //   filter: blur(2px);
  //   border-radius: 100%;
  //   z-index: 1;
  //   animation: shadow 0.5s ease infinite alternate;
  // }
  .loading{
    font-size: 12px;
    letter-spacing: 0.1em;
    display: block;
    color: $cb-color;
    position: relative;
    top: $cb-size/2;
    z-index: 2;
    animation: text 0.5s ease infinite alternate;
    }
}
@keyframes text{
  100%{
    top: ($cb-size/2) + 10;
  }
}
@keyframes shadow{
  100%{
    bottom: -18px; 
    width: $cb-size*2;
  }
}