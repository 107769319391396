.hat{
    font-size: 2.8rem;
//text-transform: Lowercase;
    line-height: 5.0rem;
    text-align: justify;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    max-width: 100vw;
    background-color: #f9f7f7; 
    color: $text-color;
    position:relative;
    
    padding-bottom: 55px;
    // transform: translateY(-470px);
    z-index:-1;
    @include RWD(tablet){
        padding-top:30px;
        transform:none;
    }

    p{
        padding-left:50px;
        padding-right:30px;
        @include RWD(tablet){
            padding-left:30px;
        }
    }

    a{
        color: $text-color;
    }
    
    & > *:last-child{
        margin-bottom: 0;
    } 

    &-outer{
        position:absolute;
        height:500px;
        top:-500px;
        width:100vw;
    }
}