.grid-morph--picto{
    .gridy__item{
        margin-bottom: 50px;
    }
    @include RWD(tablet){
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .gridy__item{
            width: 250px;
            margin-left: 20px;
            margin-right: 20px;
        }
    }
}
.a-teaser-morph--picto{
    &__visu {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
    }
    &__background{
        width: 45px;
        height: 45px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: all, 0.5s;
    }
    &__title {
        color: #000000;
        text-align: center;
        margin-bottom: 25px;
        transition: all, 0.5s;
    }
    &__description {
        color: #5a5a5a;
        text-align: center;
    }
}
a.a-teaser-morph--picto{
    text-decoration: none;
    &:hover, &:focus{
        .a-teaser-morph--picto__title {
            color: $second-color;
        }
        .a-teaser-morph--picto__background{
            transform: scale(1.3);
        }
    }
}