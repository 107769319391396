.home {
	position: relative;

	&__top {
		position: relative;

		&__header {
			position: absolute;
			width: 100%;
			top: 0px;
			z-index: 999;
			@include RWD(tablet){
				z-index:auto;
			}
		}
	}

	&__agenda {
		height: auto;
	}

	&__footer {
		z-index: 300;
	}
}





// **************************** CODE UTILE

.traits {
	&__container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-between;
		z-index: 0;

		& ~ * {
			// Permet de mettre le reste du contenu sur un plus haut z-index
			z-index: 2;
			position: relative;
		}
	}

	&__item {
		width: 1px;
		height: 100%;
		background-color: rgba(205, 205, 205, 0.5);
		z-index: 200;

		//   Simulation tablette 5 traits seulement
		@include RWD(tablet) {

			&:nth-child(7),
			&:nth-child(6) {
				display: none;
			}
		}

		@include RWD(mobile) {

			&:nth-child(7),
			&:nth-child(6) {
				display: none;
			}
		}
	}
}